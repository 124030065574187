import React from 'react';
import { Tooltip, Form, FormGroup, FormText, Alert, View, InputGroup, InputGroupText, InputGroupAddon, Input, Fade, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { withTranslation } from 'react-i18next'
import '../../i18n'
import Swal from 'sweetalert2';

import { withStyles } from '@mui/styles';
import ShotDragAndDropper from './ShotDragAndDropper'
//import * as loginaction from '../../../views/Login/action';
import {  withSnackbar } from 'notistack';
import SimpleDialog from './SimpleDialog';
import DialogJaNein from './DialogJaNein'
// Import React FilePond
import { FilePond } from "react-filepond";

// Import FilePond styles
import "./filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
/*
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
*/

import "./filepond-plugin-image-preview.css";

const settings = require('../../settings');


const styles = theme => ({
  fab: {
    margin: theme.spacing,

  },
  extendedIcon: {
    marginRight: theme.spacing,
  },
  textField: {
    width: 200,
  },
  textFieldMiddle: {
    width: 250,
  },
  textFieldLong: {
    width: 300,
  },
});



class Videoeditormodal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      shots: this.props.shots,
      status: null,
      rendercallback: false,
      singleshotfile: null,
      imageuploadid: null,
      imageuploaddata: null,
      imageuploaddecision: null,
      imagereplaceindex: null,


    };

    this.toggle = this.toggle.bind(this);

  }

  


 

  /*

  static getDerivedSateFromProps(props,state){
    if (JSON.stringify(props.shots) !== JSON.stringify(state.shots)){
      console.log('shots are: ', props.shots)
      return {
        shots: props.shots
      }
    }
    return null
  }
  */

  
  async loadShotFromJoobsterDB() {
    const { t } = this.props;
    let mypath = this.props.externalhash ? settings.externalclippath : settings.clippath

    await fetch(mypath + this.props.hash + '/shot', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) =>
      res.json()).then(async (data) => {

        const { t } = this.props;


        let localshots = data.shots;
       // localshots.map( (s) => s.expanded = false) // necessary for accordion false = closed        

        await this.setState({
          shots: localshots, 
        })


        console.log('data is:  ', data)


        this.props.enqueueSnackbar(t('mainsite.getShots.Success.title'), { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })

      })
      .catch((err) => {

        this.props.enqueueSnackbar(t('mainsite.getShots.Error.title') + ' : ' + err, { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })

      });



  }


  async addCloudItemToVideo(items){


    const {t} = this.props;

    const joobsterJSON = {
      libraryIds: items.map( item => item.id )
    }
    console.log('JSon for post in videoeditormodal is: ', joobsterJSON)


   await fetch(settings.clippath + this.props.hash + '/library' ,  {
      method: 'POST',
      body: JSON.stringify(joobsterJSON)
    }).then((res) => res.json())
    .then((data) => {


      this.setState({
        shots: data.shots
      })

      this.props.enqueueSnackbar(t('storyboard.storyboardupdatepopup.updatesuccess'), { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })


    })
    .catch((err)=>{

      this.props.enqueueSnackbar(t('storyboard.storyboardupdatepopup.errortext') + ' error:'+err, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })

    });


  }



  async postIntoJoobsterDB(){

    const {t} = this.props;

    console.log('JSon for post in videoeditormodal is: ', settings.externalclip + '/' + this.props.hash + '/shot')



   await fetch(settings.externalclippath + this.props.hash + '/shot',  {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json',
    //  'Authorization' : 'Bearer ' + this.props.auth.sessionId
      },
      body: JSON.stringify(this.state.shots)
    }).then((res) => res.json())
    .then((data) => {


      console.log('new data is: ', data)
      this.setState({
        shots: data.shots
      })

      this.props.enqueueSnackbar(t('storyboard.storyboardupdatepopup.updatesuccess'), { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })

/*
      Swal.fire({
        position: 'top-end',
        type: 'success',
        title: t('storyboard.storyboardupdatepopup.updatesuccess'),
        showConfirmButton: false,
        timer: 1000
      })
      */
    })
    .catch((err)=>{

      this.props.enqueueSnackbar(t('storyboard.storyboardupdatepopup.errortext') + ' error:'+err, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })
/*
      Swal.fire({
        title: t('storyboard.storyboardupdatepopup.errortitle'),
        position: 'center',
        text: t('storyboard.storyboardupdatepopup.errortext'),
        type: 'error',
        animation: true,
        customClass: {
          popup: 'animated tada'
        },
        showConfirmButton: false,
        timer: 1000
        //confirmButtonText: 'Cool'
      })
*/

    });



  }


  async callRenderService(){


    const {t} = this.props;
    let localJSON = {
      id: this.props.clip.id, 
      audio: {
        audioLevel: 50, 
        soundLevel: 100, 
        soundId: this.props.sound
      }
    }

    

    console.log('JSon for post in videoeditormodal is: ', localJSON)


   await fetch(settings.api_admin_path + '/noauth/clip/' + this.props.hash + '/submit',  {
      method: 'POST',      
      body: JSON.stringify( localJSON)
    }).then((res) => res.json())
    .then((data) => {

      this.setState({
        //shots: data.shots,
        status: data.status
      })

      console.log('Response: ' +  data)

      if (data.status === "ok")
      this.props.enqueueSnackbar(t('clips.videoeditormodal.callrenderservicesuccess'), { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })


      if (data.status === "error")
      this.props.enqueueSnackbar(t('clips.videoeditormodal.callrenderservicesuccess_alreadyprocessing'), { variant: 'warning', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })


    })
    .catch((err)=>{

      this.props.enqueueSnackbar(t('clips.videoeditormodal.callrenderserviceerror') + ' error:'+err, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })


    });


 //   this.setState({ status: "error"})

  }




  async toggle(e) {


    console.log('audio is now: ', this.props.sound)
/*
    if (!this.state.modal) {
      await this.loadShotFromJoobsterDB(this.props.clipid)
    }
    */


    console.log('in toggle mode ', this.props.storyboardshot)
    this.setState({
      modal: !this.state.modal,
    });


  }


  changeShot(i, timeborders){
    let localcutting = this.state.shots.find(f => f.id === i)
    localcutting = localcutting.cutting

    localcutting.start = timeborders[0];
    localcutting.stop=timeborders[1];

  }

  changeShotLT(i, timeborders){
    let localcutting = this.state.shots.find(f => f.id === i)
    localcutting = localcutting.modules.find(f => f.key === "lowerThirdBuilder")



    let localValue = JSON.parse(localcutting.value)
    localValue.startTime = timeborders[0];
    localValue.stopTime = timeborders[1];

    localcutting.value = JSON.stringify(localValue)

  }

  changeTitles(i, titles){
    let localcuttingF = this.state.shots.find(f => f.id === i)
    localcuttingF = localcuttingF.cuttingFeatures

    localcuttingF.title = titles.title;
    localcuttingF.subTitle=titles.subTitle;
  console.log('title changed: ', localcuttingF)
  }

  async changeLTTitles(i, titles){
    let localcuttingF = this.state.shots.find(f => f.id === i)
    localcuttingF = localcuttingF.modules.find(f => f.key === "lowerThirdBuilder")


    let localValue = await JSON.parse(localcuttingF.value)
    localValue[0].textHeadline = titles.textHeadline;
    localValue[0].textSubline = titles.textSubline;
    localcuttingF.value = JSON.stringify([localValue[0]])

  }


  addLowerThirdCallback(id){

    let localmodules = this.state.shots.find(f => f.id === id)

    let localvalues = {
      target:null,
      profile:"profile1",
      startTime:0,
      stopTime: localmodules.cutting.length,
      textHeadline: "",
      textSubline:"",
      tagType: "text"

    }

    localmodules.modules = [...localmodules.modules, {key: "lowerThirdBuilder", value: JSON.stringify([localvalues])}]


  }


  shotReorderCallback(s){

    s.map(( shot,i )=> shot.order = i)
  }

  async renderCallback(){
      let rs = await this.callRenderService()
  }




     imageEditorCallback(data, id, index, decision){
      const {t} = this.props;

      let localshots = this.state.shots;



      if (decision === false) // remove original image
        localshots = this.state.shots.filter( (s, idx) => s.id !== id )


      this.setState({ shots: localshots, imageuploaddata: data, imageuploadid: id, imageuploaddecision: decision, imagereplaceindex: index})


       this.pondshotsingle.addFile(data);
       this.pondshotsingle.processFiles();




     }


     changeSpeech(id){
      let localshot = this.state.shots.find(f => f.id === id)

      if ( localshot.audio.hasOwnProperty('muteSound')) delete localshot.audio.muteSound
      else localshot.audio.muteSound = true

      console.log('localshot ', localshot)
      this.setState({})

     }




  render() {

    const { classes, t } = this.props;
    const localthis = this;


    return (
      <div>



          <div style={{ display: '' }}>
                    <form id="singleFileUploadForImageEditor" className="form-horizontal" >
                      <div className="App">
                        <FilePond
                          name="uploaded_media"
                          allowBrowse={false}
                          credits={false}
                          allowDrop={false}
                          labelIdle={t('storyboard.uploadmodal.filepond.singlefileupload.labelidle')}
                          labelInvalidField={t('storyboard.uploadmodal.filepond.invalidfield')}
                          labelFileWaitingForSize={t('storyboard.uploadmodal.filepond.filewaitingforsize')}
                          labelFileSizeNotAvailable={t('storyboard.uploadmodal.filepond.filesizenotavailable')}
                          labelFileProcessing={t('storyboard.uploadmodal.filepond.fileprocessing')}
                          labelTapToCancel={t('storyboard.uploadmodal.filepond.taptocancel')}
                          labelTapToRetry={t('storyboard.uploadmodal.filepond.taptoretry')}
                          labelFileProcessingAborted={t('"storyboard.uploadmodal.filepond.fileprocessingaborted')}
                          labelFileLoading={t('storyboard.uploadmodal.filepond.fileloading')}
                          labelFileLoadError={t('storyboard.uploadmodal.filepond.fileloaderror')}
                          labelFileProcessingComplete={t('storyboard.uploadmodal.filepond.fileprocessingcomplete')}
                          labelFileProcessingError={t('storyboard.uploadmodal.filepond.fileprocessingerror')}
                          labelFileProcessingRevertError={t('storyboard.uploadmodal.filepond.fileprocessingreverterror')}
                          labelFileRemoveError={t('storyboard.uploadmodal.filepond.fileremoveerror')}
                          labelTapToUndo={t('storyboard.uploadmodal.filepond.taptoundo')}
                          labelButtonRemoveItem={t('storyboard.uploadmodal.filepond.buttonremoveitem')}
                          labelButtonAbortItemLoad={t('storyboard.uploadmodal.filepond.buttonabortitemload')}
                          labelButtonRetryItemLoad={t('storyboard.uploadmodal.filepond.buttonretryitemload')}
                          labelButtonAbortItemProcessing={t('storyboard.uploadmodal.filepond.buttonabortitemprocessing')}
                          labelButtonUndoItemProcessing={t('storyboard.uploadmodal.filepond.buttonundoitemprocessing')}
                          labelButtonRetryItemProcessing={t('storyboard.uploadmodal.filepond.buttonretryitemprocessing')}
                          labelButtonProcessItem={t('storyboard.uploadmodal.filepond.buttonprocessitem')}

                          ref={ref => (this.pondshotsingle = ref)}
                          files={this.state.singleshotfile}
                          allowMultiple={true}
                          instantUpload={true}
                          allowReorder={true}
                          allowReplace={false}
                          allowRevert={false}
                          maxFileSize={100000000}
                          allowFileTypeValidation={true}
                          allowFileSizeValidation={true}
                          acceptedFileTypes={['video/mp4', 'video/quicktime', 'image/png', 'image/jpeg', 'image/jpg']}
                          maxParallelUploads={1}
                          onprocessfiles={() => { this.setState({ singleshotfile: null, imageuploaddata: null, imageuploaddecision: null, imageuploadid: null }); //this.props.mediasCallback()

                        }}
                          server={{
                            url: '/admin/resource/shot',

                            process: {

                              headers: {
                                'X-File-Upload': 
                                JSON.stringify({ referenceId: this.state.imageuploadid, 
                                  shotDelete: !this.state.imageuploaddecision }),
                                'Authorization': 'Bearer ' + this.props.adminhash,

                              },
                              onload: (e) =>{
                                let responseShot = JSON.parse(e).shot;
                                //let localshot = this.state.shots.find(f => f.id === responseShot.id)
                                responseShot.cutting.length = 10
                                responseShot.cutting.start = 0
                                responseShot.cutting.stop = 3
                                this.state.shots.splice(this.state.imagereplaceindex, 0, responseShot)
                                console.log( 'responseshot:', e )
                                this.shotReorderCallback(this.state.shots)
                                this.postIntoJoobsterDB()
                                //this.setState({})
                                //localshot.video = responseShot.video
                              }

                            }
                          }}
                        //  oninit={() => this.handleInit()}
                          onupdatefiles={fileItems => {
                            // Set currently active file objects to this.state
                            this.setState({
                              singleshotfile: fileItems.map(fileItem => fileItem.file)
                            });

                          }}
                        />
                      </div>
                    </form>
                  </div>



            <ShotDragAndDropper
            mediasCallback={() => this.loadShotFromJoobsterDB()}
          // mediasCallback={ () =>  this.props.mediasCallback() }
            updateCallback={() => this.postIntoJoobsterDB()}
            titleCallback={(id, titles) => this.changeTitles(id,titles)}
            ltTitleCallback={(id, titles) =>this.changeLTTitles(id,titles)}
            valueCallback={(id, timeborders) => this.changeShot(id,timeborders)}
            lowerthirdCallback={(id, timeborders) => this.changeShotLT(id,timeborders)}
            addLowerThirdCallback={(id) => this.addLowerThirdCallback(id) }
            shotReorderCallback={(s) => this.shotReorderCallback(s)}
            imageEditorCallback={(data, id, index, decision)=> this.imageEditorCallback(data, id, index, decision)}
            changeSpeech={(id) => this.changeSpeech(id)}
            sound = {this.props.sound}
            soundCallback={(audio) => this.props.changeBGMusic(audio) }
            clipid = {this.props.clip.id}
            clip = {this.props.clip}
            media = {this.props.medias}
            hash={this.props.hash}
            cloudhash = {this.props.cloudhash}
            externalhash = {this.props.externalhash}
            sounds = {this.props.sounds}
            shots={

              this.state.shots

            }
            cloudItemCallback={(selectedCloudItems) => this.addCloudItemToVideo(selectedCloudItems)}
            renderCallback={() =>  this.setState({ rendercallback: true})}
            setShotCallback={ (s) => this.setState({ shots: s})}
            />









        {
          this.state.status === "error" ?

<SimpleDialog
callback={() => this.setState({status: ""})}
title={t('clips.videoeditormodal.dialogtitle')}
text={t('clips.videoeditormodal.dialogtext')} />

        :
        <DialogJaNein text={t('clips.videoeditor.dialogyesno.text')} open={this.state.rendercallback}
        callback={ (e) =>  { e &&  this.renderCallback(); this.setState({rendercallback: false})} } />
      }


      </div>
    );
  }
}





export default withSnackbar(withStyles(styles)(withTranslation()(Videoeditormodal)));

