import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import { withStyles } from '@mui/styles';
import '../../../i18n'
import { withTranslation } from 'react-i18next'
import CopyIcon from '@mui/icons-material/FileCopy';


const styles = theme => ({
  root: {
    width: '100%',
  },

  fab: {
    margin: theme.spacing,
    //background: 'pink', 
    marginTop: '-3px', 
   

  },


  extendedIcon: {
    marginRight: theme.spacing,
  },
});

class DialogJaNein extends React.Component {
//  const
//  const [open, setOpen] = React.useState(false);

  constructor(props){
      super(props)
      this.state = {
          open: false,
          useroption: null
      }


  }

   handleClickOpen() {

    this.setState({open: true})
  };

 async  handleClose(e,option){

        await this.setState({open: false, useroption: option})
        this.callback()

  };

   callback() {
      this.props.callback(this.state.useroption)
  }

  render(){

    const { classes,t  } = this.props;

  return (
    <span>

        {
          this.props.url ? <img style={{ borderRadius: 5 + 'px'}} height="55" src={this.props.url} onClick={() => this.handleClickOpen()}  alt="image error" /> : null
        }
        {
          this.props.buttonLabel ? <Button style={{background: "red", color: "white"}} color="primary" onClick={() => this.handleClickOpen()}>{this.props.buttonLabel}</Button> : null
        }
        {
          this.props.icon ? <Fab color="primary" onClick={() => this.handleClickOpen()}  aria-label="Delete" className={classes.fab}> {this.props.icon} </Fab> : null
        }
        {
     this.props.copyicon ? <Fab size="small" style={{background: "green", color: "white"}} aria-label="Copy" className={classes.fab} onClick={() => this.handleClickOpen()}><CopyIcon /></Fab>: null
        }    


      <Dialog
        open={this.state.open}
        onClose={(e) => this.handleClose(e,false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.props.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => this.handleClose(e,true)} color="primary">
            {t('storyboard.dialogyes')}
          </Button>
          <Button onClick={(e) => this.handleClose(e,false)} color="primary" autoFocus>
            {t('storyboard.dialogno')}
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}

}

export default   withTranslation()(withStyles(styles)(DialogJaNein));
