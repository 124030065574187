import React, { Component } from 'react'
import SelectSearch from 'react-select-search';
import { withTranslation } from 'react-i18next'
import './selectsearchbox.css';
import '../../i18n'
import 'bootstrap/dist/css/bootstrap.min.css';

class TransitionDropDown extends Component {


  constructor(props) {
    super(props);
    this.state = {
        currentSupergroup: null,
        newCatTitle: '',
        transitionid: this.props.transitionid ||null,
        selectOptions: null,
    }


}


static getDerivedStateFromProps(props, state) {

  if (props.transitionid && props.transitionid !== state.transitionid  ){

    return {
      transitionid : props.transitionid
    }
  }

  return null;
}


componentDidMount(){
  const {t} = this.props

  var options = []

  var transitions =
  [
    { "transition" : t('clips.videoeditormodal.transitionsdropdown.standard'), "photo": "https://admin.joobster.at/img/transitions/whiteTransition.jpg" },
    { "transition" : t('clips.videoeditormodal.transitionsdropdown.black'),  "photo": "https://admin.joobster.at/img/transitions/blackTransition.jpg" },
    { "transition" : t('clips.videoeditormodal.transitionsdropdown.white'),  "photo": "https://admin.joobster.at/img/transitions/whiteTransition.jpg" }
]

console.log('transitionid is: ', this.state.transitionid)

  transitions.map( l => {
    options.push({   name: l.transition, value: l.transition,  photo: l.photo })
  })

  this.setState({ selectOptions: options})
}



 changeTransition = (event) => {

  this.props.i18n.changeLanguage(event.target.value)
}



getChangedValueOfLanguage= async (e) =>  {

  await this.setState({
    transitionid: e
  })

 // this.props.i18n.changeLanguage(e.value)
  this.props.callback && this.props.callback(e)


}



render() {

  const {t } = this.props;

  function renderTransitions(props, option, snapshot, className) {
    const imgStyle = {
      borderRadius: '5%',
      verticalAlign: 'left',
      marginRight: 10,
      border: "1px solid #000"
    };


    var pictureformatter = <button  {...props} className={className}>
    <span><span><img style={imgStyle} key={option.value} width="60" height="40"   src={option.photo}/> </span>{option.name}</span>
    </button>

    return pictureformatter;
  }


  return (
    <div >


      {
        this.state.selectOptions &&
        <SelectSearch
        onChange={this.getChangedValueOfLanguage}
        name="transition"
        value={this.state.transitionid}
        multiple={false} height={150}
        options={this.state.selectOptions}
        placeholder={this.props.placeholder}
        renderOption={renderTransitions} />
      }


    </div>
  );
    }


}



export default withTranslation()(TransitionDropDown)
