import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import '../../i18n'
import { withTranslation } from 'react-i18next'


const styles = theme => ({
  root: {
    width: '100%',
  },

  fab: {
    margin: theme.spacing,
    //background: 'pink', 
    marginTop: '-3px', 
   

  },


  extendedIcon: {
    marginRight: theme.spacing,
  },
});

class DialogJaNein extends React.Component {
//  const
//  const [open, setOpen] = React.useState(false);

  constructor(props){
      super(props)
      this.state = {
        
          useroption: null
      }


  }


 async  handleClose(e,option){

        await this.setState({useroption: option})
        this.callback()
      

  };

   callback() {
      this.props.callback(this.state.useroption)
  }

  render(){

    const { classes,t  } = this.props;

  return (
    <span>


      <Dialog
        open={this.props.open}
        onClose={(e) => this.handleClose(e,false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.props.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => this.handleClose(e,true)} color="primary">
            {t('storyboard.dialogyes')}
          </Button>
          <Button onClick={(e) => this.handleClose(e,false)} color="primary" autoFocus>
            {t('storyboard.dialogno')}
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}

}

export default   withTranslation()(withStyles(styles)(DialogJaNein));
