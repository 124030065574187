import React from 'react';
import { makeStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import TransitionDropDown from './TransitionDropDown'
import {
     Button, Badge as Badge2 } from 'reactstrap';
  import Radio from '@mui/material/Radio';
  import RadioGroup from '@mui/material/RadioGroup';
  import FormControlLabel from '@mui/material/FormControlLabel';
  import FormControl from '@mui/material/FormControl';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    
    width: 500,
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: theme.boxShadow,
    padding: theme.spacing,
    left: "50%", 
    top: "50%", 
    transform: `translate(-50%, -50%)`,
  },
}));

export default function TransitionDropDownModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  
  const [open, setOpen] = React.useState(false);

  const [value, setValue] = React.useState('single');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const transitionDropDownCallback = (t) => {
      console.log('transition is: ', value)
      props.callback(t, value)
      setOpen(false)
  };

  const body = (
    <div  className={classes.paper}>
      <h2 id="simple-modal-title">Choose transition</h2>
      <FormControl component="fieldset">      
      <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
        <FormControlLabel value="single" control={<Radio />} label="Ausgewählter Shot" />
        <FormControlLabel value="all" control={<Radio />} label="Alle Shots" />
      </RadioGroup>
    </FormControl>

      <TransitionDropDown callback={(trans) => transitionDropDownCallback(trans)}
        transitionid={ props.transitionid } />
    </div>
  );

  return (
    <div>

{
    props.type === "middle" ? 

      <Button style={{ 
                                    position: "absolute", 
                                    borderRadius: 30, 
                                    width: 50, 
                                    height: 50, 
                                    left: "50%", 
                                    transform: "translate(-50%, -10%)", 
                                    backgroundColor: props.backgroundColor, 
                                    zIndex: 1000, 
                                  }} 
                                  onClick={handleOpen}
                                  /> 
                                  : props.type === "last" ? 
                                  <Button style={{ 
                                    position: "absolute", 
                                    borderRadius: 30, 
                                    width: 50, 
                                    height: 50, 
                                    left: "50%", 
                                    transform: "translate(-50%, -35%)", 
                                    backgroundColor: props.backgroundColor, 
                                    zIndex: 1000, 
                                  }} 
                                  onClick={handleOpen}
                                  />    
                                  : props.type === "first"   ? 
                                  
<Button style={{ 
                                    position: "absolute", 
                                    borderRadius: 30, 
                                    width: 50, 
                                    height: 50, 
                                    left: "50%", 
                                    transform: "translate(-50%, -65%)", 
                                    backgroundColor: props.backgroundColor, 
                                    zIndex: 1000, 
                                  }} 
                                  onClick={handleOpen}
                                  />                                  
                                  
                                  : null

                                }

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
