import React, { Component } from 'react';
import { withStyles } from '@mui//styles';
import { findDOMNode } from 'react-dom'
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../../i18n'
import { withTranslation } from 'react-i18next'
import Slider from '@mui/material/Slider';
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'
import Playbutton from '@mui/icons-material/PlayArrow'
import Stopbutton from '@mui/icons-material/Stop'
import Fullscreenbutton from '@mui/icons-material/Fullscreen'
import LinearProgress from '@mui/material/LinearProgress';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';
import './player.css'
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import 'videojs-wavesurfer/dist/css/videojs.wavesurfer.css';
import Wavesurfer from 'videojs-wavesurfer/dist/videojs.wavesurfer.js';

const styles = theme => ({
  root: {
    width: 300,
},

  fab: {
    margin: theme.spacing,

  },
  extendedIcon: {
    marginRight: theme.spacing,
  },
  textField: {
    width: 200,
  },
  textFieldMiddle: {
    width: 250,
  },
  textFieldLong: {
    width: 300,
  },
});



class Videotrimmer extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            value: this.props.value,
            currval: 0, //the current time for the slider
            url: this.props.video,
            pip: false,
            playing: false,
            controls: false,
            light: false,
            volume: 0.8,
            muted: false,
            played: 0,
            loaded: 0,
            duration: 0,
            playbackRate: 1.0,
            loop: false,


            error: false,
           // selectedInterval: [selectedStart, selectedEnd],

        };

        this.handleChange = this.handleChange.bind(this)
        this.handleSeekChange = this.handleSeekChange.bind(this)
        this.handlePlay = this.handlePlay.bind(this)
 //       this.changeCurrentTime = this.changeCurrentTime.bind(this);
  //      this.seek = this.seek.bind(this);

    }



    componentDidMount() {

/*
      this.wavesurfer = WaveSurfer.create({
      container: document.querySelector("#waveform" + this.props.id),
        barWidth: 3,
        cursorWidth: 1,
        backend: 'MediaElement',
        height: 80,
        progressColor: '#2D5BFF',
        responsive: true,
        waveColor: '#EFEFEF',
        cursorColor: 'transparent',
      });

      this.wavesurfer.load(this.props.video);


*/

      if (! this.props.isImage) {

      const videoJsOptions = {
        controls: false,
        bigPlayButton: false,
        width: '100%',
        height: '100%',
        fluid: true,
        plugins: {
            wavesurfer: {
                backend: 'MediaElement',
                displayMilliseconds: true,
                debug: true,
                waveColor: '#fbcb30',
                barWidth: 1,
               // progressColor: '#fbcb30',
                cursorColor: 'black',
                hideScrollbar: true,
                height: '100',
                responsive: true,
                normalize: true,
                muted: true,
                volume: 0,

            }
        }
    };

    /*
    this.wavesurfer = WaveSurfer.create({
      container: document.querySelector("#waveform" + this.props.id),
...videoJsOptions.plugins.wavesurfer
    });
    this.wavesurfer.load(this.props.video);
*/

      this.waveplayer = videojs(this.videoNode, {...videoJsOptions},  () => {
          // print version information at startup
          this.waveplayer.src({src: this.props.video, type: 'video/mp4'});
      });


      this.waveplayer.on('waveReady', (event) => {
          console.log('waveform: ready!');
      });

      this.waveplayer.on('playbackFinish', (event) => {
          console.log('playback finished.');
      });

      this.waveplayer.on('error', (element, error) => {
          console.error(error);
      });

    }

  }

  // destroy player on unmount
  componentWillUnmount() {
      if (this.waveplayer) {
          this.waveplayer.dispose();
      }
  }



/*
    componentDidMount() {
        // subscribe state change
        this.player.subscribeToStateChange(this.handleStateChange.bind(this));

      }



      handleStateChange(state) {
        // copy player state to this component's state
        console.log('now in handlestatechange')
        this.setState({
          player: state
        });
      }

      seek(seconds) {
        return () => {
          this.player.seek(seconds);
        };
      }


      changeCurrentTime(seconds) {
          console.log('time changed to ', seconds)
        return () => {
          const { player } = this.player.getState();
          this.player.seek(seconds);
        };
      }


      */

   async handleChange(e, newValue, action) {

       let currentval1 = this.state.value[0]
       let currentval2 = this.state.value[1]
       //let curTime = 0




       if (currentval1 != newValue[0]) {
        await this.setState({currval : newValue[0]})
     //   curTime=newValue[0]
        } else

        if (currentval2 != newValue[1]) {
         await this.setState({ currval : newValue[1]})
       //   curTime=newValue[1]
      }


      if (!this.props.isImage) {
this.player.seekTo(this.state.currval/this.props.max)
this.waveplayer.wavesurfer().surfer.seekTo(this.state.currval/this.props.max)
    }
//action.navigate(this.state.currval)

console.log('time is : ' + this.state.currval/this.props.max+ ' statevalue: ' + this.state.value.toString() + ' newValue: ', newValue.toString())
//this.setState({ playing: false })
        this.setState({ value: newValue });
        this.props.valueCallback(newValue)

    }




  load = url => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false
    })
  }

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing })
    if (!this.props.isImage)  this.waveplayer.wavesurfer().pause()

  }

  handleStop = () => {
    this.setState({ url: null, playing: false })
  }

  handleToggleControls = () => {
    const url = this.state.url
    this.setState({
      controls: !this.state.controls,
      url: null
    }, () => this.load(url))
  }

  handleToggleLight = () => {
    this.setState({ light: !this.state.light })
  }

  handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop })
  }

  handleVolumeChange = (e,v) => {
    console.log('volume is: ', e+ ' ' + v)
    this.setState({ volume: parseFloat(e) })
  }

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted })
  }

  handleSetPlaybackRate = e => {
    this.setState({ playbackRate: parseFloat(e.target.value) })
  }

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip })
  }

  handlePlay = (actions) => {
    console.log('onPlay', this.state.value)
    if (this.state.value && this.state.value.length > 0 ) {
      this.player.seekTo(this.state.value[0]/this.props.max)

      if (!this.props.isImage) {
      this.waveplayer.wavesurfer().setVolume(0) // methods are here: https://wavesurfer-js.org/docs/methods.html
      this.waveplayer.wavesurfer().surfer.seekTo(this.state.value[0]/this.props.max)



      this.waveplayer.wavesurfer().play()
      }
    //  actions.navigate(this.state.value[0])
    }

  //  actions.play()

  this.setState({ playing: true })
  }

  handleEnablePIP = () => {
    console.log('onEnablePIP')
    this.setState({ pip: true })
  }

  handleDisablePIP = () => {
    console.log('onDisablePIP')
    this.setState({ pip: false })
  }

  handlePause = () => {
    console.log('onPause')
    if (!this.props.isImage)  this.waveplayer.wavesurfer().pause()
    this.setState({ playing: false })

  }

  handleSeekMouseDown = e => {
    this.setState({ seeking: true })
  }

  handleSeekChange = e => {
    this.setState({ played: parseFloat(e.target.value) })
  }

  handleSeekMouseUp = e => {
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(e.target.value))
  }

  handleProgress = state => {
    console.log('onProgress', state)
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state)
    }

    if (this.state.played >= parseFloat(this.state.value[1]/this.props.max)){
      //if ( this.player * 1000 >= this.state.value[1]*1000){

        if (!this.props.isImage)  this.waveplayer.wavesurfer().pause()
    this.setState({
      playing: false
    })
   // console.log('stopped at : ', this.player)
  }
  }

  handleEnded = () => {
    console.log('onEnded')
    this.setState({ playing: this.state.loop })
  }

  handleDuration = (duration) => {
    console.log('onDuration', duration)
    this.setState({ duration })
  }

  handleClickFullscreen = () => {
    screenfull.request(findDOMNode(this.player))
  }

  renderLoadButton = (url, label) => {
    return (
      <button onClick={() => this.load(url)}>
        {label}
      </button>
    )
  }





    valuetext(value) {
        return `${value}s`;
    }



        ref = player => {
          this.player = player
        }



    render() {

        const { classes, t } = this.props;
        const localthis = this;
        const { url, playing, controls, light, volume, muted, loop, played, loaded, duration, playbackRate, pip } = this.state

        const normalise = (value, min, max) => (value - min) * 100 / ( max -  min);



        return (
            <div className={classes.root}>


{
  !this.props.isImage ?


              <div id={"waveform" + this.props.id} data-vjs-player key={this.props.id}>
            <video id={"myvid" + this.props.id} ref={node => this.videoNode = node}  className="video-js vjs-default-skin"></video>
        </div>
        : null
}




   {
     this.state.value ?
   <div >


{
 /*
  playing && this.waveplayer ? (
    this.waveplayer.wavesurfer().volume = 0,
    this.waveplayer.wavesurfer().play()
  )
  : this.waveplayer ? (
    this.waveplayer.wavesurfer().volume = 0,
  this.waveplayer.wavesurfer().surfer.seekTo(this.state.value[0] > 0 ? this.state.value[0]/this.props.max : 0),
  this.waveplayer.wavesurfer().pause()
  )
  : null
  */
}


{
  /*
<div id={"waveform" + this.props.id}>
<video id={"myvid" + this.props.id} ref={node => this.videoNode = node} url={this.props.video} ></video>
</div>
  */
}


{
  this.props.isImage ?
<img width={240} src={this.props.poster} />
 :
<div>
<React.Fragment> <LinearProgress variant="determinate" value={playing ? normalise(duration*played , this.state.value[0],  this.state.value[1]) : 0} /></React.Fragment>


        <div className="player-wrapper">
        <ReactPlayer
        id={"reactplayer_"+this.props.id}
        ref={this.ref}
        url={this.props.video}
        className='react-player'
        playing={this.state.playing}
        onSeek={e => console.log('onSeek',e)}
        controls={true}
        light={false}
        onDuration={this.handleDuration}
        onPlay={this.handlePlay}
        name="video"
        playbackRate={playbackRate}
        volume={volume}
        muted={muted}
        onReady={() => console.log('onReady')}
        onStart={() => console.log('onStart')}
        onEnablePIP={this.handleEnablePIP}
        onDisablePIP={this.handleDisablePIP}
        onPause={this.handlePause}
        onBuffer={() => console.log('onBuffer')}
        //onSeek={e => console.log('onSeek', e)}
        onEnded={this.handleEnded}
        onError={e => console.log('onError', e)}
        onProgress={this.handleProgress}
        //onDuration={this.handleDuration}
        progressInterval={10}
        width="100%"
        height="100%"

        />
        </div>

        </div>
    }
    <br/>

{
  /*

<Video
ref={this.videoRef}
children={ c => console.log(c)}
src={this.props.video} controls
onTimeChange={ (t) =>  console.log(Math.round(t * 1000)) }
>
  {(video, state, actions) => {


    console.log('currentTime: ', state.currentTime )

    if (state.status === "playing" && state.currentTime=== this.state.value[1]) {
      //actions.pause();
      console.log(state.currentTime + ' ' + this.state.value[1])
  //   actions.navigate(this.state.value[1]);

    }


    return(
    <div>
      {video}
      <div>{state.currentTime} / {state.duration} / {state.buffered}</div>
      <LinearProgress variant="determinate" value={normalise(state.currentTime - this.state.value[0], 0, this.state.value[1] - this.state.value[0])} />

      <Grid container spacing={2}>
        <Grid item>
          <VolumeDown />
        </Grid>
        <Grid item xs>
          <Slider value={state.volume*100} onChange={ (e,v) => actions.setVolume(v/100)} aria-labelledby="continuous-slider" />
        </Grid>
        <Grid item>
          <VolumeUp />
        </Grid>
      </Grid>

      {
        state.status !== "playing" ?

      <Fab size="small" color="primary" aria-label="PlayVideo" className={classes.fab} onClick={() => this.handlePlay(actions)} >
          <Playbutton />
        </Fab>
        :
        <Fab size="small" color="primary" aria-label="StopVideo" className={classes.fab} onClick={() => { actions.pause()}} >
        <Stopbutton />
      </Fab>
  }

<Fab size="small" color="primary" aria-label="FullscreenVideo" className={classes.fab} onClick={() => {actions.requestFullscreen()}} >
        <Fullscreenbutton />
      </Fab>


<br/>
<br/>
<br/>
      <Slider
                    value={this.state.value}
                    onChange={(e,n) => this.handleChange(e, n, actions)}
                    valueLabelDisplay="on"
                    aria-labelledby="range-slider"
                    getAriaValueText={(e) => this.valuetext(e)}
                    min={this.props.min}
                    max={this.props.max}
                    step={this.props.step}

                />

    </div>
    )
    }}
</Video>
*/
  }

{
  /*

<div data-vjs-player>
            <video id="myVideo" ref={node => this.videoNode = node} className="video-js vjs-default-skin"></video>
        </div>
          */
}



<Slider
                    value={this.state.value}
                    onChange={this.handleChange}
                    valueLabelDisplay="on"
                    aria-labelledby="range-slider"
                    getAriaValueText={(e) => this.valuetext(e)}
                    min={this.props.min}
                    max={this.props.max}
                    step={this.props.step}

                />

{
  !this.props.isImage ?

<div>
<Grid container spacing={2}>
  <Grid item>
    <VolumeDown />
  </Grid>
  <Grid item xs>
    <Slider value={volume*100} onChange={(e,v) => this.handleVolumeChange(v/100)} aria-labelledby="continuous-slider" />
  </Grid>
  <Grid item>
    <VolumeUp />
  </Grid>
</Grid>


{
        !playing ?

      <Fab size="small" color="primary" aria-label="PlayVideo" className={classes.fab} onClick={() => this.handlePlay()} >
          <Playbutton />
        </Fab>
        :
        <Fab size="small" color="primary" aria-label="StopVideo" className={classes.fab} onClick={() =>  this.handlePlayPause()} >
        <Stopbutton />
      </Fab>
  }



<Fab size="small" color="primary" aria-label="FullscreenVideo" className={classes.fab} onClick={() => this.handleClickFullscreen()} >
        <Fullscreenbutton />
      </Fab>

</div>
: null
    }


{
  /*

<table>
            <tbody>
              <tr>
                <th>Controls</th>
                <td>
                  <button onClick={this.handleStop}>Stop</button>
                  <button onClick={this.handlePlayPause}>{playing ? 'Pause' : 'Play'}</button>
                  <button onClick={this.handleClickFullscreen}>Fullscreen</button>
                </td>
              </tr>
              </tbody>
              </table>

<table>
            <tbody>
              <tr>
                <th>url</th>
                <td className={!url ? 'faded' : ''}>
                  {(url instanceof Array ? 'Multiple' : url) || 'null'}
                </td>
              </tr>
              <tr>
                <th>playing</th>
                <td>{playing ? 'true' : 'false'}</td>
              </tr>
              <tr>
                <th>volume</th>
                <td>{volume.toFixed(3)}</td>
              </tr>
              <tr>
                <th>played</th>
                <td>{played.toFixed(3)}</td>
              </tr>
              <tr>
                <th>loaded</th>
                <td>{loaded.toFixed(3)}</td>
              </tr>
              <tr>
                <th>duration</th>
                <td><Duration seconds={duration} /></td>
              </tr>
              <tr>
                <th>elapsed</th>
                <td><Duration seconds={duration * played} /></td>
              </tr>
              <tr>
                <th>remaining</th>
                <td><Duration seconds={duration * (1 - played)} /></td>
              </tr>
            </tbody>
          </table>
  */
}


</div>

        : null}






            </div>
        );
    }
}


export default withTranslation()(withStyles(styles)(Videotrimmer));


