import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { findDOMNode } from 'react-dom'
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../../i18n'
import { withTranslation } from 'react-i18next'
import Slider from '@mui/material/Slider';
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'
import Playbutton from '@mui/icons-material/PlayArrow'
import Stopbutton from '@mui/icons-material/Stop'
import Fullscreenbutton from '@mui/icons-material/Fullscreen'
import LinearProgress from '@mui/material/LinearProgress';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';
import './player.css'
import TitleUpdater from './TitleUpdater'


const styles = theme => ({
  root: {
    width: 300,
},

  fab: {
    margin: theme.spacing,

  },
  extendedIcon: {
    marginRight: theme.spacing,
  },
  textField: {
    width: 200,
  },
  textFieldMiddle: {
    width: 250,
  },
  textFieldLong: {
    width: 300,
  },
});



class Videotrimmer extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            value: this.props.value,
            currval: 0, //the current time for the slider
            url: this.props.video,
            pip: false,
            playing: false,
            controls: false,
            light: false,
            volume: 0.8,
            muted: false,
            played: 0,
            loaded: 0,
            duration: 0,
            playbackRate: 1.0,
            loop: false,


            error: false,
           // selectedInterval: [selectedStart, selectedEnd],

        };

        this.handleChange = this.handleChange.bind(this)
        this.handleSeekChange = this.handleSeekChange.bind(this)
        this.handlePlay = this.handlePlay.bind(this)


    }




   async handleChange(e, newValue, action) {

       let currentval1 = this.state.value[0]
       let currentval2 = this.state.value[1]


       if (currentval1 != newValue[0]) {
        await this.setState({currval : newValue[0]})
        } else

        if (currentval2 != newValue[1]) {
         await this.setState({ currval : newValue[1]})
      }


this.player.seekTo(this.state.currval/this.props.max)

console.log('time is : ' + this.state.currval/this.props.max+ ' statevalue: ' + this.state.value.toString() + ' newValue: ', newValue.toString())
        this.setState({ value: newValue });
        this.props.valueCallback(newValue)

    }



  load = url => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false
    })
  }

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing })
  }

  handleStop = () => {
    this.setState({ url: null, playing: false })
  }

  handleToggleControls = () => {
    const url = this.state.url
    this.setState({
      controls: !this.state.controls,
      url: null
    }, () => this.load(url))
  }

  handleToggleLight = () => {
    this.setState({ light: !this.state.light })
  }

  handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop })
  }

  handleVolumeChange = (e,v) => {
    console.log('volume is: ', e+ ' ' + v)
    this.setState({ volume: parseFloat(e) })
  }

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted })
  }

  handleSetPlaybackRate = e => {
    this.setState({ playbackRate: parseFloat(e.target.value) })
  }

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip })
  }

  handlePlay = (actions) => {
    console.log('onPlay', this.state.value)
    if (this.state.value && this.state.value.length > 0 ) {
      this.player.seekTo(this.state.value[0]/this.props.max)
    }


  this.setState({ playing: true })
  }

  handleEnablePIP = () => {
    console.log('onEnablePIP')
    this.setState({ pip: true })
  }

  handleDisablePIP = () => {
    console.log('onDisablePIP')
    this.setState({ pip: false })
  }

  handlePause = () => {
    console.log('onPause')
    this.setState({ playing: false })
  }

  handleSeekMouseDown = e => {
    this.setState({ seeking: true })
  }

  handleSeekChange = e => {
    this.setState({ played: parseFloat(e.target.value) })
  }

  handleSeekMouseUp = e => {
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(e.target.value))
  }

  handleProgress = state => {
    console.log('onProgress', state)
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state)
    }

    if (this.state.played >= parseFloat(this.state.value[1]/this.props.max)){
      //if ( this.player * 1000 >= this.state.value[1]*1000){


    this.setState({
      playing: false
    })
   // console.log('stopped at : ', this.player)
  }
  }

  handleEnded = () => {
    console.log('onEnded')
    this.setState({ playing: this.state.loop })
  }

  handleDuration = (duration) => {
    console.log('onDuration', duration)
    this.setState({ duration })
  }

  handleClickFullscreen = () => {
    screenfull.request(findDOMNode(this.player))
  }

  renderLoadButton = (url, label) => {
    return (
      <button onClick={() => this.load(url)}>
        {label}
      </button>
    )
  }



    valuetext(value) {
        return `${value}s`;
    }


        ref = player => {
          this.player = player
        }



    render() {

        const { classes, t } = this.props;
        const localthis = this;
        const { url, playing, controls, light, volume, muted, loop, played, loaded, duration, playbackRate, pip } = this.state



        const normalise = (value, min, max) => (value - min) * 100 / ( max -  min);



        return (
            <div className={classes.root}>

   {
     this.state.value ?
   <div >

<React.Fragment> <LinearProgress variant="determinate" value={playing ? normalise(duration*played , this.state.value[0],  this.state.value[1]) : 0} /></React.Fragment>


        <div className="player-wrapper">
        <ReactPlayer
        ref={this.ref}
        url={this.props.video}
        className='react-player'
        playing={this.state.playing}
        onSeek={e => console.log('onSeek',e)}
        controls={true}
        light={false}
        onDuration={this.handleDuration}
        onPlay={this.handlePlay}
        name="video"
        playbackRate={playbackRate}
        volume={volume}
        muted={muted}
        onReady={() => console.log('onReady')}
        onStart={() => console.log('onStart')}
        onEnablePIP={this.handleEnablePIP}
        onDisablePIP={this.handleDisablePIP}
        onPause={this.handlePause}
        onBuffer={() => console.log('onBuffer')}
        onSeek={e => console.log('onSeek', e)}
        onEnded={this.handleEnded}
        onError={e => console.log('onError', e)}
        onProgress={this.handleProgress}
        onDuration={this.handleDuration}
        progressInterval={10}
        width="100%"
        height="100%"

        />
        </div>

        <br/>




<Slider
                    value={this.state.value}
                    onChange={this.handleChange}
                    valueLabelDisplay="on"
                    aria-labelledby="range-slider"
                    getAriaValueText={(e) => this.valuetext(e)}
                    min={this.props.min}
                    max={this.props.max}
                    step={this.props.step}

                />

<Grid container spacing={2}>
  <Grid item>
    <VolumeDown />
  </Grid>
  <Grid item xs>
    <Slider value={volume*100} onChange={(e,v) => this.handleVolumeChange(v/100)} aria-labelledby="continuous-slider" />
  </Grid>
  <Grid item>
    <VolumeUp />
  </Grid>
</Grid>


{
        !playing ?

      <Fab size="small" color="primary" aria-label="PlayVideo" className={classes.fab} onClick={() => this.handlePlay()} >
          <Playbutton />
        </Fab>
        :
        <Fab size="small" color="primary" aria-label="StopVideo" className={classes.fab} onClick={() =>  this.handlePlayPause()} >
        <Stopbutton />
      </Fab>
  }


<Fab size="small" color="primary" aria-label="FullscreenVideo" className={classes.fab} onClick={() => this.handleClickFullscreen()} >
        <Fullscreenbutton />
      </Fab>

<TitleUpdater titleCallback={(e) => this.props.ltTitleCallback(e)} row={this.props.ltvalue} title="textHeadline" subtitle="textSubline"/>


</div>

        : null}






            </div>
        );
    }
}


export default withTranslation()(withStyles(styles)(Videotrimmer));


