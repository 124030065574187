import React from 'react';
import ExpansionPanel from '@mui/material/Accordion';
import ExpansionPanelSummary from '@mui/material/AccordionSummary';
import ExpansionPanelDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid2 from '@mui/material/Grid';
import '../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { withStyles } from '@mui/styles';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, numberFilter} from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import PlayAudio from 'react-audio-player'
import Quoting from './Quoting'
import '../../i18n'
import { withTranslation } from 'react-i18next'
import 'bootstrap/dist/css/bootstrap.min.css';



const styles = theme => ({
  root: {
    width: '100%',

  },

  fab: {
    margin: theme.spacing,
    background: 'red'

  },
  column: {
    flexBasis: '45%'
  },

  margin: {
    margin: theme.spacing,
    //margin: 'auto',

  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  button: {
    margin: theme.spacing,

  },

  center: {
    margin: 'auto'

  }


});



class SoundDefinition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: "",
      images: [],
      loading: true,
      sound: {}
    };


    this.handleKeyPress = this.handleKeyPress.bind(this);


  }





  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }





handleKeyPress(event){
  if(event.key == 'Enter'){
    this.searchFieldClicked();
  }
}


   render() {

    var localthis = this;
    const { classes, t } = this.props;




    function IDFormatter(cell, row, rowIndex, formatExtraData) {
      return (
          <p>{row.id}</p>
      );
  }

  function titleFormatter(cell, row, rowIndex, formatExtraData) {
    return (
        <p>{row.title}</p>
    );
}
function licenseFormatter(cell, row, rowIndex, formatExtraData) {
  const {t} = localthis.props; 
  return (
    <div>
      <p><strong>{row.title}</strong> by {row.artistName}</p>
      <p>{row.license}</p><br/>
      <a href={row.link} target="_blank" > {t('storyboard.musicselection.table.licenselink')}</a>
      </div>
  );
}
function durationFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <div>
      <p>{row.duration}</p>
      </div>
  );
}
function genreFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    row.genre.map( (gen, idx) =>
        idx !== row.genre.length-1 ? gen + ", " : gen
      )

  );
}

  function soundFormatter(cell, row, rowIndex, formatExtraData) {


      return (
          <div>

              <PlayAudio

              preload="none"
                            controls
                            src={row.url} />
              </div>
      );
  }


    const columns = [
      {
        dataField: 'id',
        text: 'Sound ID',
        hidden: true,
        formatter: IDFormatter,
        //filter: textFilter(),
              sort: true,

        headerStyle: () => {
          return { width: "5%" };
              }

      },
      {
      dataField: 'title',
      text: t('storyboard.musicselection.table.title'),
      headerTitle: false,
      formatter: titleFormatter,
          filter: textFilter({
            placeholder: t('storyboard.musicselection.table.titlesearch')
          }),
          headerStyle: () => {
              return { width: "20%" };
          }

      },
      {
        dataField: 'genre',
        text: t('storyboard.musicselection.table.genre'),
        headerTitle: false,
        formatter: genreFormatter,
            filter: textFilter({
              placeholder: t('storyboard.musicselection.table.genresearch')
            }),
            headerStyle: () => {
                return { width: "10%" };
            }


        },
{
  dataField: 'url',
  text: t('storyboard.musicselection.table.sound'),
  formatter: soundFormatter,
  //    filter: textFilter(),
      headerStyle: () => {
          return { width: "25%" };
      }

  },
  {
    dataField: 'license',
    text: t('storyboard.musicselection.table.license'),
    formatter: licenseFormatter,
    //    filter: textFilter(),
        headerStyle: () => {
            return { width: "30%" };
        }

    }   ,
    {
      dataField: 'duration',
      text: t('storyboard.musicselection.table.seconds'),
      formatter: durationFormatter,
          filter: numberFilter({
            getFilter: (filter) => {
              // pricerFilter was assigned once the component has been mounted.
              this.state.durationFilter = filter;
            },
            placeholder: t('storyboard.musicselection.table.secondssearch'), 
          headerStyle: () => {
              return { width: "20%" };
          }   })

      }


    ];



    const selectRow = {
      mode: 'checkbox',
      classes: 'selection-row',
      //headerFormatter: selectFormatter,
      onSelect: (row, isSelect, rowIndex, e) => {
      //	if (isSelect) this.setSelectedRow(row.id, row, true);
      this.setState({sound: row})
      this.props.callback(row)
      },
      onSelectAll: (isSelect, rows, e) => {
        console.log(isSelect);
        console.log(rows);
        console.log(e);
      }

      };

          /*
      function selectFormatter(column, colIndex, { sortElement, filterElement }) {
      return (
        <div style={ { display: 'flex', flexDirection: 'column' } }>
        { filterElement }
        { column.text }
        { sortElement }
        </div>
      );
      }
  */

      const rowEvents = {
        onClick: (e, row, rowIndex) => {
          console.log(row);
        }
      }

      const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          {t('storyboard.musicselection.tablesoundtrack')} { from } - { to } {t('storyboard.musicselection.tablesoundtrackfrom')} { size } 
        </span>
      );


      const rowStyle2 = (row, rowIndex) => {
        const style = {};
        if (rowIndex%2== 0) {
          style.backgroundColor = '#fbca30';
        } else {
          style.backgroundColor = '#ffffff';
        }

        return style;
      };

      const options = {
        paginationSize: 4,
        pageStartIndex: 0,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{
          text: '10', value: 10
        }, {
          text: '25', value: 25
        }, {
          text: '50', value: 50
        }, {
          text: 'All', value: localthis.props.sounds && localthis.props.sounds!= null ? localthis.props.sounds.length : 0
        }] // A numeric array is also available. the purpose of above example is custom the text
      };




    return (
      <div>



 <div className={styles.root}>
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <div className={styles.column}>
    <img top="true" width="10%"   src={this.state.logo ? this.state.logo : 'https://www.joobster.at/wp-content/uploads/2018/05/joobster_logo_black-1024x460.jpg'} alt="Firmenlogo" />
        </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>


              <Grid2 container className={styles.container} >
                <Grid2 item xs={12} >

                  <Grid2 container spacing={16} alignitems="center" justify="center" direction="row" >


                  <Quoting />

                  </Grid2>

                </Grid2>
              </Grid2>



        </ExpansionPanelDetails>
      </ExpansionPanel>
      </div>

      {


        this.props.sounds && this.props.sounds.length > 0 ?

          <div style={{ margin: "10px"}}>
            <BootstrapTable keyField='id'

              data={this.props.sounds}
              filter={filterFactory()}
              columns={columns}
              selectRow={selectRow}
              rowEvents={rowEvents}
              pagination={paginationFactory(options)}
              rowStyle={ rowStyle2 }
            />
            </div>
        : null


   }

</div>



    );


  }


}



export default withTranslation()(withStyles(styles)(SoundDefinition));


