import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';
import 'tui-color-picker/dist/tui-color-picker.css';
import React from 'react';
import {  Button } from 'reactstrap';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import '../../../i18n'

import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ImageIcon from '@mui/icons-material/Image';
import DialogJaNein from './DialogJaNein'
const icona = require("tui-image-editor/dist/svg/icon-a.svg");
const iconb = require("tui-image-editor/dist/svg/icon-b.svg");
const iconc = require("tui-image-editor/dist/svg/icon-c.svg");
const icond = require("tui-image-editor/dist/svg/icon-d.svg");


const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    fab: {
      margin: theme.spacing,
      width: 100,
      borderRadius: 5,
      backgroundColor: "#FFC107",
      color: "#ffffff"
    },
    title: {
      marginLeft: theme.spacing,
      flex: 1,
    },

  }));

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


export default function JImageEditor(props) {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const imageeditor = React.createRef();
    const { t } = useTranslation();

    const myTheme = {
        "menu.backgroundColor": "white",
        "common.backgroundColor": "#151515",
        "downloadButton.backgroundColor": "white",
        "downloadButton.borderColor": "white",
        "downloadButton.color": "black",
        "menu.normalIcon.path": icond,
        "menu.activeIcon.path": iconb,
        "menu.disabledIcon.path": icona,
        "menu.hoverIcon.path": iconc,
       // "header.display" : "none"
       "common.bi.image" : 'https://www.joobster.at/wp-content/uploads/2018/05/joobster_logo_black-1024x460.jpg',
       'common.bisize.width': '205px',
       'common.bisize.height': '92px',
      };



      const saveImageToDisk = (jimageeditordecision) => {
        const imageEditorInst = imageeditor.current.imageEditorInst;
        console.log(imageEditorInst)

        /*

        const instance = colorPicker.create({
          container: container,
          usageStatistics: false,

        });

   */




        const data = imageEditorInst.toDataURL();
        if (data) {
          const mimeType = data.split(";")[0].split(":")[1];
          const extension = data.split(";")[0].split("/")[1];
        //  download(data, `image.${extension}`, mimeType);
        console.log('data = ' + mimeType + ' ' + extension)
        var f = new File([data], "image."+extension, {type: mimeType})

        props.callback(f, jimageeditordecision)
        handleClose()

        }



      };


      const handleClickOpen = () => {
        setOpen(true);
      };


      const handleClose = () => {
        setOpen(false);
      };

/*
      useEffect(() => {
          imageeditor.current = imageeditor.current.getInstance()
         console.log('imageeditor ref is: ', imageeditor)
         // imageeditor.current.focus()
      })
      */

      const handleClickButton = () => {
        const editorInstance = imageeditor.current.getInstance();

       // editorInstance.flipX();
      };

      var locale_de_DE = {
        // override default English locale to your custom
        'Crop': t("clips.jimageeditor.crop"), // as result default English inscription will be translated into Russian
        'DeleteAll': t("clips.jimageeditor.deleteall"),
        'Delete' : t("clips.jimageeditor.delete"),
        'Reset' : t("clips.jimageeditor.reset"),
        'Redo' : t("clips.jimageeditor.Redo"),
        'Undo' : t("clips.jimageeditor.Undo"),
        'History' : t("clips.jimageeditor.history"),
        "Hand" : t("clips.jimageeditor.hand"),
        "ZoomOut" : t("clips.jimageeditor.zoomout"),
        "ZoomIn" : t("clips.jimageeditor.zoomin"),
        "Load" : t("clips.jimageeditor.load"),
        "Download" : t("clips.jimageeditor.download"),
        "Shape" : t("clips.jimageeditor.shape"),
        "Filter" : t("clips.jimageeditor.filter"),
        "Text" : t("clips.jimageeditor.text"),
        "Flip" : t("clips.jimageeditor.flip"),
        "Rotate" : t("clips.jimageeditor.rotate"),
        "Icon" : t("clips.jimageeditor.icon"),
        "Mask" : t("clips.jimageeditor.mask"),
        "Draw" : t("clips.jimageeditor.draw"),
        "Arrow" : t("clips.jimageeditor.arrow1"),
        "Arrow-2" : t("clips.jimageeditor.arrow2"),
        "Arrow-3" : t("clips.jimageeditor.arrow3"),
        "Star-1" : t("clips.jimageeditor.star1"),
        "Star-2" : t("clips.jimageeditor.star2"),
        "Polygon" : t("clips.jimageeditor.polygon"),
        "Location" : t("clips.jimageeditor.location"),
        "Heart" : t("clips.jimageeditor.heart"),
        "Bubble" : t("clips.jimageeditor.bubble"),
        "Custom icon" : t("clips.jimageeditor.customicon"),
        "Color" : t("clips.jimageeditor.color"),
        "Load Mask Image" : t("clips.jimageeditor.loadmaskimage"),
        "Apply" : t("clips.jimageeditor.apply"),
        "Free" : t("clips.jimageeditor.free"),
        "Straight" : t("clips.jimageeditor.straight"),
        "Range" : t("clips.jimageeditor.range"),
        "Rectangle" : t("clips.jimageeditor.rectangle"),
        "Circle" : t("clips.jimageeditor.circle"),
        "Triangle" : t("clips.jimageeditor.triangle"),
        "Fill" : t("clips.jimageeditor.fill"),
        "Stroke" : t("clips.jimageeditor.stroke"),
        "Grayscale" : t("clips.jimageeditor.grayscale"),
        "Sepia" : t("clips.jimageeditor.sepia"),
        "Blur" : t("clips.jimageeditor.blur"),
        "Emboss" : t("clips.jimageeditor.emboss"),
        "Invert" : t("clips.jimageeditor.invert"),
        "Sepia2" : t("clips.jimageeditor.sepia2"),
        "Sharpen" : t("clips.jimageeditor.sharpen"),
        "Remove White" : t("clips.jimageeditor.removewhite"),
        "Distance" : t("clips.jimageeditor.distance"),
        "Brightness" : t("clips.jimageeditor.brightness"),
        "Noise" : t("clips.jimageeditor.noise"),
        "Pixelate" : t("clips.jimageeditor.pixelate"),
        "Color Filter" : t("clips.jimageeditor.colorfilter"),
        "Threshold": t("clips.jimageeditor.threshold"),
        "Tint": t("clips.jimageeditor.tint"),
        "Multiply": t("clips.jimageeditor.multiply"),
        "Blend": t("clips.jimageeditor.blend"),
        "Bold": t("clips.jimageeditor.bold"),
        "Italic": t("clips.jimageeditor.italic"),
        "Underline": t("clips.jimageeditor.underline"),
        "Left": t("clips.jimageeditor.left"),
        "Center": t("clips.jimageeditor.center"),
        "Right": t("clips.jimageeditor.right"),
        "Text size": t("clips.jimageeditor.textsize"),
        "Custom": t("clips.jimageeditor.custom"),
        "Square": t("clips.jimageeditor.square"),
        "Cancel": t("clips.jimageeditor.cancel"),
        "Flip X": t("clips.jimageeditor.flipx"),
        "Flip Y": t("clips.jimageeditor.flipy"),
        "Opacity": t("clips.jimageeditor.opacity"),
      };

        return(

            <div>


                        <div>
                            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                                <ImageIcon /> {props.text}
                            </Button>
                        </div>




      <Dialog disableEnforceFocus color="primary" fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar

        className={classes.appBar} color="primary">
          <Toolbar style={{ backgroundColor: "#20AAD8"}}>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
            {t('clips.jimageeditor.dialogheader')}
            </Typography>

          {
            props.tableelement  ?


            <DialogJaNein buttonLabel={t('clips.jimageeditor.dialogsave')}
            text={t('clips.jimageeditor.dialogyesnotext')}
            callback={(e)=> saveImageToDisk(e)} />
            :
            <Button style={{background: "red", color: "white"}} color="primary" onClick={() => saveImageToDisk(true)}>{t('clips.jimageeditor.dialogsave')}</Button>

          }




          </Toolbar>
        </AppBar>

        <ImageEditor
            ref={imageeditor }

            includeUI={{

              loadImage: {
                path: props.link || null,
                name: 'SampleImage',
              },
              theme: myTheme,
              menu: ['shape', 'filter', 'text', 'crop', 'flip', 'rotate', 'icon' , 'mask', 'draw'],
              locale: locale_de_DE,
              initMenu: 'filter',
              uiSize: {
                width: '100%',
                height: '800px',


              },
              menuBarPosition: 'bottom',

            }}
            cssMaxHeight={500}
            cssMaxWidth={700}
            selectionStyle={{
              cornerSize: 20,
              rotatingPointOffset: 70,
            }}
            usageStatistics={false}
          />

      </Dialog>



            </div>




        )
    }




