import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid2 from '@mui/material/Grid';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { withTranslation } from 'react-i18next'
import '../i18n'
import {  withSnackbar } from 'notistack';
import Videoeditormodal from './Videoeditor/Videoeditormodal'
import 'bootstrap/dist/css/bootstrap.min.css';

const settings = require('../settings');


const styles = theme => ({
  root: {
    width: '100%',

  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular

  },

  column: {
    flexBasis: '45%'
  },

  margin: {
    margin: theme.spacing,
    //margin: 'auto', 

  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  button: {
    margin: theme.spacing,

  },

  center: {
    margin: 'auto'

  },
  fab3: {
    margin: theme.spacing,
    display: 'flex',
    //marginRight: "auto" ,
    //justifyContent: 'flex-end',
    // position: 'absolute',
    // right: '0',
    float: 'right',
    background: '#28a745'
  },


});



class Initialpage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      urlvalue: [],
      logo: "",
      shots: null,
      language: '',
      hash: '', 
      clip: null, 
      cloudhash: null, 
      adminhash: null, 
      externalhash: null, 
      medias: null, 
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
  }




  async componentDidMount() {
    
    

    var langCode = await this.getLangCode();
    var cloudhash = await this.getCloudHash()
    var adminhash = await this.getAdminHash()
    var externalhash = await this.getExternalHash()
    console.log('urlvalue: ', window.location.href)
    var hash = await this.getHashFromUrl()
    this.changeLanguage(langCode)
    var IDUrlParameterValue = null
    let returnFetch = await this.getSoundsFromJoobsterDB()

    await this.setState({
      urlvalue: window.location.href,
      language: langCode,
      hash, 
      sounds: returnFetch.sounds, 
      cloudhash, 
      adminhash, 
      externalhash
      //logo: "https://www.joobster.at/wp-content/uploads/2018/05/joobster_logo_black-1024x460.jpg"
    })
    //console.log('url value is: ', this.state.urlvalue)

    await this.getShotsFromJoobsterServer()
  }


  async getSoundsFromJoobsterDB() {

    //var awaitFetch =   await fetch(settings.api_base_path + '/api/1.0/public/sounds', { method: 'GET' }) 
    var awaitFetch =   await fetch(settings.api_music + '/api/1.0/public/sounds', { method: 'GET' }) 

    let returnFetch = await awaitFetch.json()

      console.log('fetchval ', returnFetch)


      return returnFetch

    }


  changeLanguage = (lC) => {
    this.props.i18n.changeLanguage(lC)
  }


  getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }

	changeBGMusic(_joobsterJSON, audio){

		_joobsterJSON.audio.soundId =  audio.id
		_joobsterJSON.audio.audioLevel = 50
		_joobsterJSON.audio.soundLevel = 100
/*
		this.props.onUpdateClip({
			authToken: this.props.auth.sessionId,
			groupId: _joobsterJSON.groupId,
			clip: _joobsterJSON
	  });
    */
	
    this.updateBGMusicOnjoobsterServer(_joobsterJSON)
		console.log(" clip: ", { clip: _joobsterJSON})
		
		

	}


   updateBGMusicOnjoobsterServer( jsonparameter){

    const {t} = this.props;

    fetch(settings.clippath + this.state.hash  ,  {
      method: 'PUT',
      body: JSON.stringify(jsonparameter)
    }).then((res) => res.json())
    .then((data) => {

     // let localshots = data.shots;
     // localshots.map( (s) => s.expanded = false) // necessary for accordion false = closed        

      /*
      this.setState({
        shots: localshots, 
        clip: data.clip
      })
      */

      this.props.enqueueSnackbar(t('storyboard.storyboardupdatepopup.updatesuccess'), { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })


    })
    .catch((err)=>{

      this.props.enqueueSnackbar(t('storyboard.storyboardupdatepopup.errortext') + ' error:'+err, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })

    });

  }



  async getLangCode() {

    return this.getUrlVars()['languageCode'];
  }

  async getAdminHash() {

    return this.getUrlVars()['adminhash'];
  }

  async getExternalHash() {

    return this.getUrlVars()['externalhash'] ;
  }

  async getCloudHash() {

    return this.getUrlVars()['cloudhash'];
  }

  async getSupergroupID() {

    return this.getUrlVars()['sg'];
  }

  async getHashFromUrl() {


   // return this.getUrlVars()['questionhash'];
   //console.log('url is: ', window.location.href)
   //return window.location.href.split('/?clip/')[1].split('/shot')[0]
   return this.getUrlVars()['cliphash'];

  }


  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }


  async shotsCallback() {
    await this.getShotsFromJoobsterServer()
    // window.location.reload();

  }


  async getShotsFromJoobsterServer() {

    const { t } = this.props;
    let mypath = this.state.externalhash ? settings.externalclippath : settings.clippath

    await fetch(mypath + this.state.hash + '/shot', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) =>
      res.json()).then(async (data) => {

        const { t } = this.props;


        let localshots = data.shots;
        localshots.map( (s) => s.expanded = false) // necessary for accordion false = closed        

        await this.setState({
          shots: localshots, 
          clip: data.clip, 
          medias: data.medias
        })


        console.log('data is:  ', data)


        this.props.enqueueSnackbar(t('mainsite.getShots.Success.title'), { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })

      })
      .catch((err) => {

        this.props.enqueueSnackbar(t('mainsite.getShots.Error.title') + ' : ' + err, { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })

      });

      
  }


  handleKeyPress(event) {
    if (event.key == 'Enter') {
      this.searchFieldClicked();
    }
  }

  render() {

    const { t } = this.props;

    return (
      <div>


        <div className={styles.root}>
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className={styles.column}>
                <img top="true" width="10%" src={this.state.logo ? this.state.logo : 'https://www.joobster.at/wp-content/uploads/2018/05/joobster_logo_black-1024x460.jpg'} alt="Firmenlogo" />  {t('mainsite.slogan')}
              </div>
            </AccordionSummary>
            <AccordionDetails>

              <Grid2 container className={styles.container} >
                <Grid2 item xs={12} >
                  <Grid2 container spacing={16} alignitems="center" justify="center" direction="row" >

                    <p>{t('mainsite.clipheader')}</p>

                  </Grid2>

                </Grid2>
              </Grid2>
              

            </AccordionDetails>
          </Accordion>
        </div>

        {
          this.state.clip && this.state.sounds && this.state.medias ? <div>         
			<Videoeditormodal 
            hash = {this.state.hash}
            mediasCallback = { () => {  this.getShotsFromJoobsterServer();}}
            changeBGMusic={(audio) => this.changeBGMusic(this.state.clip, audio)} 
            shots = {this.state.shots}
            medias = {this.state.medias}
            clip = {this.state.clip}
            sounds = {this.state.sounds}
            cloudhash = {this.state.cloudhash}
            externalhash = {this.state.externalhash}
            adminhash = {this.state.adminhash}
            sound={this.state.clip.audio.hasOwnProperty('soundId') ? this.state.clip.audio.soundId : {}}/>
          </div> :
            null
        }
      </div>
    );

  }

}

export default withSnackbar(withTranslation()(Initialpage));

