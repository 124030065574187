module.exports = {

    clippath: 'https://viewer2.joobster.at/clip/', 
    externalclippath: 'https://viewer2.joobster.at/externalclip/', 
    librarypath: 'https://viewer2.joobster.at/library/',
    api_base_path: 'https://api2.joobster.at/', //dev
    api_music: 'https://api2.joobster.at',
    api_admin_path: 'https://api2.joobster.at',
 //   api_service_path: 'https://service2.joobster.at'
	
}
