import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import '../../i18n'
import { withTranslation } from 'react-i18next'



const styles = theme => ({
  root: {
    width: '100%',
  },

  fab: {
    margin: theme.spacing,
    //background: 'pink', 
    marginTop: '-3px', 
   

  },


  extendedIcon: {
    marginRight: theme.spacing,
  },
});

class SimpleDialog extends React.Component {
//  const
//  const [open, setOpen] = React.useState(false);

  constructor(props){
      super(props)
      this.state = {
          open: true,
          useroption: null
      }


  }

  

   handleClickOpen() {

    this.setState({open: true})
  };

 async  handleClose(e,option){

        await this.setState({open: false, useroption: option})
        this.props.callback()

  };


  render(){

    const { t  } = this.props;

  return (
    <span>


      <Dialog
        open={this.state.open}
        onClose={(e) => this.handleClose(e,false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.props.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => this.handleClose(e,true)} color="primary">
            {t('clips.videoeditor.dialog.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}

}

export default   withTranslation()(withStyles(styles)(SimpleDialog));
