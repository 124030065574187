import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next'
import '../../i18n'
//import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import SelectSearch from 'react-select-search';
//import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
//import SaveIcon from '@material-ui/icons/Save';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
//import i18next from 'i18next'
import CameraAltIcon from '@mui/icons-material/CameraAlt';
//import Swal from 'sweetalert2';
//import Fab from '@material-ui/core/Fab';
import CloudVideos from './CloudVideos'
import CloudIcon from '@mui/icons-material/Cloud';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/MeetingRoom';
//import 'react-bootstrap-table-next/dist/react-bootstrap-custom-table2.min.css';


const settings = require('../../settings');


// pick shots from Cloud


const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  rootIndicator: {
    flexGrow: 1,
  },
  chip: {
    margin: theme.spacing,
  },

  fab: {
    margin: theme.spacing,
    display: 'flex',
    //marginRight: "auto" ,
    //justifyContent: 'flex-end',
    // position: 'absolute',
    // right: '0',
    float: 'left',
    background: 'yellow',
    height: "46px",
    fontFamily: "Roboto, sans-serif",
    borderRadius: "0px",



  },
  extendedIcon: {
    marginRight: theme.spacing,
  },
  progress: {
    margin: theme.spacing,
  },
});



class Cloudpicker extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cloudid: null,
      directoryStructure: [],
      choosenDir: null,
      selectOptions: null,
      parentId: null,
      fileMap: {},
      modal: false,
      shots: null,
      selectedItems: [] // selected cloud items from table, which the user has choosen to add! they come from cloudvideos-class

    };

    this.getChangedValueOfParent = this.getChangedValueOfParent.bind(this);

    this.toggle = this.toggle.bind(this);
    //this._handleKeyPress = this._handleKeyPress.bind(this);
  }


  /*
  async componentDidMount(){
  
  
    await this.getFileListingFromJoobsterDB()
    console.log('filemap: ', this.state.fileMap)
    var dirs = await this.generateDirStructure()
    console.log('dirs: ', dirs)
    var selectOptions = dirs.map( ds => {
      return  { id: ds.directory.id, name: ds.directory.name , value: ds.directory.id }
    })
  
    this.setState( { directoryStructure: dirs, selectOptions });
  
  }
  
*/

  async getChangedValueOfParent(e) {
    console.log("Object in getChangedValueOfParent is: ", e)

    let localshots = await this.getMediaFromCloudDir(e ? e : 'my-root-id')
    await this.setState({
      cloudid: e,
      shots: localshots
    })
    //this.props.choosenDirCallback([this.state.cloudid, 'calpop'])



  }

  /*
  
  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(props.directoryStructure) !== JSON.stringify(state.directoryStructure)) {
  
      var dirs = props.dirs()
      var selectOptions = dirs.map( ds => {
        return  { id: ds.directory.id, name: ds.directory.name , value: ds.directory.id }
      })
  
      return { directoryStructure: dirs, selectOptions };
    }
  
  
    return null
  }
  */

  /* getLevelDistance is used for SelectSearch-Component in order to make paragraph indent (small spaces (distances) depending on category-level) */

  getLevelDistance(id) {


    var matchingcategory = this.state.directoryStructure.find(f => f.directory.id === id)
    if (!matchingcategory) return null
    var retval = '';


    var i;
    var elementArray = new Array();
    var lastIndex = matchingcategory.level - 1;

    for (i = 0; i < matchingcategory.level; i++) {
      elementArray.push(<span> &nbsp; </span>)
    }



    return (
      elementArray.map((elem, ind) => {
        if (lastIndex === ind) {
          return <span><ArrowRightIcon /></span>
        } else {
          return elem
        }
      })
    );


  }

  async generateDirStructure() {
    var files = this.state.fileMap
    console.log('filemap is: ', files)
    var dirs = Object.keys(files).map((key) => files[key]);
    var dirTree = []
    this.buildDirTable("my-root-id", 0, dirs, dirTree)
    return dirTree
  }


  /* BEGIN OF functions for building the directorytree */

  buildDirTable(parent, level, allDirObjects, dirTree) {//recursive function for building directories
    let dirs = [];
    dirs = this.getConnectedDirs(allDirObjects, parent);

    if (dirs)
      dirs.map(dir => {
        //if ( dir.id !== this.state.currentFolderId){
        dirTree.push({ level: level, directory: dir })
        this.buildDirTable(dir.id, level + 1, allDirObjects, dirTree);
        //}
      })
  };

  getConnectedDirs(allDirObjects, parentid) { // return all directories which belong to the given parent
    let dirs = [];
    allDirObjects.map(currlevel => {
      if (currlevel.parentId === parentid && currlevel.isDir) {
        dirs.push(currlevel);
      }
    });
    return dirs;
  }

  /* END OF BUILDING FUNCTIONS FOR DIRECTORYTREE */


  async getFileListingFromJoobsterDB() {
    const { t } = this.props;
    console.log('get link: ', settings.librarypath + this.props.cloudhash + '/directory')

    await fetch(settings.librarypath + this.props.cloudhash + '/directory', {
      method: 'GET',
    }).then((res) => res.json()).then((data) => {



      var files = data.directories;
      console.log("response from getGeneralFiles: ", files)

      for (const key in files) {
        files[key].name = files[key].name === "Main Folder" ? files[key].name = t('myspace.space.mainfolder') : files[key].name
      }

      this.setState({
        currentFolderId: "my-root-id",
        fileMap: files,
        fileSelection: {},
      })
      this.props.enqueueSnackbar(t('myspace.jooexplorer.getfilelisting.datasuccess'), { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })


    })

  }

  async toggle(e) {


    console.log('modalstate is:', this.state.modal)

    if (!this.state.modal) {
      await this.getFileListingFromJoobsterDB()
      console.log('filemap: ', this.state.fileMap)
      var dirs = await this.generateDirStructure()
      console.log('dirs: ', dirs)
      var selectOptions = dirs.map(ds => {
        return { id: ds.directory.id, name: ds.directory.name, value: ds.directory.id }
      })

      this.setState({ directoryStructure: dirs, selectOptions });
    }


    this.setState({
      modal: !this.state.modal
    })


  }


  async getMediaFromCloudDir(id) {


    console.log('id is: ', id)

    this.props.fileMap &&
      console.log('mediaelement: ', Object.entries(this.state.fileMap))

    let jtoaobj = Object.entries(this.state.fileMap)
    let retObj = []

    jtoaobj.map(entry => {
      if (entry[1].parentId === id && entry[1].media.mediaUrl !== undefined) {

        var isimage = ['jpg', 'png'].includes(entry[1].media.mediaUrl.split('.').pop())
        var isvideo = ['mp4', 'mov'].includes(entry[1].media.mediaUrl.split('.').pop())

        if (['jpg', 'png', 'mp4', 'mov'].includes(entry[1].media.mediaUrl.split('.').pop()))
          retObj.push({
            id: entry[1].id, media: entry[1].media,
            mediaType: isimage ? "image" : isvideo ? "video" : undefined,
            metadata: entry[1].metadata,
            video: {
              video: entry[1].media.mediaUrl,
              videoThumbLow: entry[1].media.mediaThumb
            }
          })
      }
    })
    console.log('medialement is: ', retObj)

    return retObj

  }


  render() {

    const localthis = this;
    const { t, classes } = this.props

    function renderCategories(props, option, snapshot, className) {
      const imgStyle = {
        borderRadius: '50%',
        verticalAlign: 'middle',
        marginRight: 10
      };


      var pictureformatter;
      (option.photo == null || option.photo == "") ? pictureformatter =
        <button  {...props} className={className}><span>{localthis.getLevelDistance(option.id)}<span><CameraAltIcon /></span> {option.name} </span></button> :
        pictureformatter = <button  {...props} className={className}><span>{localthis.getLevelDistance(option.id)}<span><img style={imgStyle} key={option.value} width="40" height="40" src={option.photo} /> </span>{option.name}</span></button>

      return pictureformatter;
    }



    return (
      <div>

        <Button disabled={!this.props.cloudhash} color="primary" onClick={() => this.toggle()} >
          <CloudIcon />
          {' '} {t('clips.videoeditormodal.cloudname')}


        </Button>


        <Modal size="xl" style={{ maxWidth: '800px', width: '60%' }} isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{t('clips.cloudpicker.header')}</ModalHeader>
          <ModalBody>


            {
              this.state.selectOptions ?
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', marginLeft: '5px', paddingBottom: "15px" }}>

                  <SelectSearch

                    onChange={(e) => this.getChangedValueOfParent(e)}
                    name="cloud dir"
                    value={this.state.cloudid}
                    multiple={false}
                    height={172}
                    width={600}
                    search
                    filterOptions={(e) => {
                      return (v) => {
                        if (!v.length) return e
                        return e.filter(o => o.name.toUpperCase().includes(v.toUpperCase()))
                      }
                    }}
                    options={this.state.selectOptions}
                    placeholder={t('clips.cloudpicker.dropdown')}
                    renderOption={renderCategories} />

                </div>
                : null
            }

            {
              this.state.selectedItems.length > 0 ?
                <div style={{ float: 'right', paddingBottom: "15px" }} >
                  <Button color="primary" onClick={() => { this.props.cloudItemCallback(this.state.selectedItems); this.toggle() }}>{t('viewer.add')}
                    <AddIcon />
                  </Button>

                </div>

                : null
            }



            {
              <CloudVideos
                shots={this.state.shots}
                checkedRows={(e) => this.setState({ selectedItems: e })}
              />

            }


          </ModalBody>

          <ModalFooter>
            <Button color="primary" onClick={() => this.toggle()}> {t('clips.videocreationmodal.close')}<CloseIcon /></Button>

            {
              this.state.selectedItems.length > 0 ?

                <Button color="primary" onClick={() => { this.props.cloudItemCallback(this.state.selectedItems); this.toggle() }}>{t('viewer.add')}
                  <AddIcon />
                </Button>
                : null
            }

          </ModalFooter>

        </Modal>

      </div>


    )
  }

}




export default withStyles(styles)(withTranslation()(withSnackbar(Cloudpicker)));
