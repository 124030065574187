import React, { Component } from 'react';
import {
  UncontrolledTooltip, Row, Col, InputGroup, InputGroupAddon, InputGroupButton, Fade, Input, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, Container,
  Card, Badge as Badge2, CardBody, Alert, CardHeader, CardTitle, Form, FormGroup, FormFeedback, CardFooter
} from 'reactstrap';
import { withStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import DeleteIcon from '@mui/icons-material/Delete';
import UpIcon from '@mui/icons-material/ArrowUpward';
import DownIcon from '@mui/icons-material/ArrowDownward';
import FormControlLabel from '@mui/material/FormControlLabel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SaveButton from '@mui/icons-material/Save'
import VideoButton from '@mui/icons-material/MovieCreation'
import Switch from '@mui/material/Switch';
import '../../i18n'
import { withTranslation } from 'react-i18next'
import Videotrimmer from './Videotrimmer'
import TransitionDropDown from './TransitionDropDown';
import Timer from '@mui/icons-material/Timer'
import TitleUpdater from './TitleUpdater'
import LowerThird from './LowerThird';
import LowerThirdIcon from '@mui/icons-material/Toc'
import 'bootstrap/dist/css/bootstrap.min.css';
import Badge from '@mui/material/Badge';
import JImageEditor from './JImageEditor/JImageEditor'
import TransitionDropDownModal from './TransitionDropDownModal';
import SpeechbubbleIconDOWN from '@mui/icons-material/VolumeDown'
import SpeechbubbleIconUP from '@mui/icons-material/VolumeUp'
import Cloudpicker from './Cloudpicker';
import UploadModal from './UploadModal'
import SoundDefinitionModal from './SoundDefinitionModal'
import settings from '../../settings';

const styles = theme => ({
  fab: {
    margin: theme.spacing,

  },
  fablong: {
    margin: theme.spacing,
    borderRadius: 8,
    width: 80

  },

  audiodown: {
    backgroundColor: "#7CFC00",
    color: "#fff",
    margin: theme.spacing,

  },
  audioup: {

    margin: theme.spacing,

  },
  extendedIcon: {
    marginRight: theme.spacing,
  },
  textField: {
    width: 200,
  },
  textFieldMiddle: {
    width: 250,
  },
  textFieldLong: {
    width: 300,
  },
  root: {
    '& > *': {
      margin: theme.spacing,
    },
  },

});


const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "#007BFF",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "#ffffff",
  padding: grid,
  width: '100%'
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class ShotDragAndDropper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shots: this.props.shots,
      AccordionsExpanded: false,
      unikey: 0,
      isDragDisabled: false,
      showlowerthird: false,
      sound: {},
    };

    this.onDragEnd = this.onDragEnd.bind(this);

  }



  static getDerivedStateFromProps(props, state){

    if (JSON.stringify(props.shots) !== JSON.stringify(state.shots) ){
      console.log('props changed ', props.shots)
      return (
        {
          shots: props.shots
        }
      )
    }
    return null
  }



async componentDidMount(){
  let s = await this.getSoundInfosFromID(this.props.sound)
  this.setState({ sound: s})
}

/*
async componentDidUpdate(){
  if (JSON.stringify(this.props.shots) !== JSON.stringify(this.state.shots) ){

    this.setState({ shots: this.props.shots})
  }

}
*/


async getSoundInfosFromID(id){
  const {t} = this.props;
  let clip = this.props.clip
  let sound = this.props.sounds.find(f => f.id === clip.audio.soundId)
  return sound ? sound : {id: 'wrong', title: t('storyboard.storyboardupdatepopup.titlenotexisting')}
}

  /*

  delStoryboardShot(position) {

    let localstbq = this.state.shots;
    localstbq.splice(position, 1)

    //  this.reformatShotIDs();

    this.setState({ shots: localstbq })
  }
  */

/*
  generateJSON() {
    let localJSON = this.props.storyboarddata

    localJSON.name = this.state.name
    localJSON.note = this.state.note
    localJSON.shots = this.state.shots.map(shot => {

      let localshot = shot

      if (shot.uuid) {
        localshot.uuid = shot.uuid
      }
      localshot.description = shot.description
      localshot.question = shot.question
      localshot.answer = shot.answer
      localshot.parameters = shot.parameters




      return localshot;
    })
    localJSON.emails = [...this.state.emails]


    return JSON.stringify(localJSON)

  }
  */

/*

  async updateStoryboardToJoobsterServer(e) {


    var json = this.generateJSON();
    console.log('json is: ', json)
    const {t} = this.props;

    await fetch(settings.api_base_path + settings.api_admin_path + '/supergroup/' + this.props.sg.id + '/storyboard/' + this.props.storyboarddata.id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.auth.sessionId
      },
      body: json
    }).then((res) => res.json())
      .then((data) => {
        Swal.fire({
          position: 'top-end',
          type: 'success',
          title: t('storyboard.storyboardupdatepopup.update.success'),
          showConfirmButton: false,
          timer: 1000
        })
      })
      .catch((err) => {
        Swal.fire({
          title: t('storyboard.storyboardupdatepopup.update.errortitle'),
          position: 'center',
          text: t('storyboard.storyboardupdatepopup.update.errortext'),
          type: 'error',
          animation: true,
          customClass: {
            popup: 'animated tada'
          },
          showConfirmButton: false,
          timer: 1000
          //confirmButtonText: 'Cool'
        })
      });

    await this.props.storyboardcallback()



  }

  currentChangeListener(name, position, e) {


    let findshot = this.state.shots[position]

    if (name == "question_arrayelem") {
      findshot.question = e.target.value
      this.setState({})
    }
    if (name == "description_arrayelem") {
      findshot.description = e.target.value
      this.setState({})
    }
    if (name == "answer_arrayelem") {
      findshot.answer = e.target.value
      this.setState({})
    }
    if (name == "question") this.setState({ currentquestion: e.target.value })
    if (name == "description") this.setState({ currentdescription: e.target.value })
    if (name == "answer") this.setState({ currentanswer: e.target.value })

    if (name == "name")
      this.setState({
        name: e.target.value
      })
    if (name == "hinweis")
      this.setState({
        note: e.target.value
      })
  }
  */

  async move(array, from, to) {
    if (to === from) return array;

    var target = array[from];
    var increment = to < from ? -1 : 1;

    for (var k = from; k != to; k += increment) {
      array[k] = array[k + increment];
    }
    array[to] = target;
    return array;
  }

  async remove(id){

      let localshots = this.state.shots.filter(f => f.id !== id)
      this.setState({shots: localshots})


      this.props.setShotCallback(localshots)

  }

  async newPosition(currentposition, direction) {

    let from, to;
    from = currentposition;

    if (direction == "up" && currentposition != 0) to = currentposition - 1;
    if (direction == "down" && currentposition != this.state.shots.length - 1) to = currentposition + 1;

    if (currentposition == this.state.shots.length - 1 && direction == "down") {
      from = currentposition;
      to = 0
    }

    if (currentposition == 0 && direction == "up") {
      from = currentposition;
      to = this.state.shots.length - 1;
    }


    let localshots = []

   localshots =  await this.move(this.state.shots, from, to)
    //this.reformatShotIDs();

   // await this.setState({ shots: localshots })
   //this.setState({})

    this.props.shotReorderCallback(localshots)
    this.props.setShotCallback(localshots)
  }



  removeTransition(transitionType, pos, shots){ // transitionType is ["fadeIn", "fadeOut"], pos is current position of Shot


    let shotres = shots[pos].filter.map( filterelem => {
      let parsedElem = JSON.parse(filterelem)
      if ( transitionType.includes(parsedElem.name) === false  ) return filterelem
    })

    shotres = shotres.filter(Boolean)

    shots[pos].filter = shotres

  }



  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }






    const shots = reorder(
      this.state.shots,
      result.source.index,
      result.destination.index
    );

    /*
    this.setState({
      shots
    });
    */

    let directiondown = result.source.index < result.destination.index ? true : false
    console.log('directiondown is: ', directiondown)


    if (result.source.index === 0 ) {
      //this.removeTransition(["fadeOut", "fadeIn"], result.source.index, shots)
      this.removeTransition(["fadeIn"], result.source.index, shots)
      this.removeTransition(["fadeOut"], result.destination.index-1, shots)
      this.removeTransition(["fadeOut", "fadeIn"], result.destination.index, shots)
    } else

    if (result.source.index === this.state.shots.length -1  ){
      this.removeTransition(["fadeOut"], result.source.index, shots)
      this.removeTransition(["fadeIn"], result.destination.index+1, shots)
      this.removeTransition(["fadeIn", "fadeOut"], result.destination.index, shots)
    } else
    {

      if (directiondown === false ){
        if (result.destination.index !== 0 )
        {
          this.removeTransition(["fadeIn", "fadeOut"], result.destination.index, shots)
          this.removeTransition(["fadeOut"], result.destination.index-1, shots)
          this.removeTransition(["fadeIn"], result.destination.index+1, shots)
          this.removeTransition(["fadeIn"], result.source.index+1, shots)
          this.removeTransition(["fadeOut"], result.source.index, shots)
          }
          else{
            this.removeTransition(["fadeIn", "fadeOut"], result.destination.index, shots)
            this.removeTransition(["fadeIn"], result.destination.index+1, shots)
            this.removeTransition(["fadeIn"], result.source.index+1, shots)
            this.removeTransition(["fadeOut"], result.source.index, shots)
          }
      }else{

        if (result.destination.index < shots.length - 1){
          this.removeTransition(["fadeIn", "fadeOut"], result.destination.index, shots)
          this.removeTransition(["fadeOut"], result.destination.index-1, shots)
          this.removeTransition(["fadeIn"], result.destination.index+1, shots)
          this.removeTransition(["fadeOut"], result.source.index-1, shots)
          this.removeTransition(["fadeIn"], result.source.index, shots)
            }
            else{
              this.removeTransition(["fadeIn", "fadeOut"], result.destination.index, shots)
              this.removeTransition(["fadeOut"], result.destination.index-1, shots)
              this.removeTransition(["fadeOut"], result.source.index-1, shots)
              this.removeTransition(["fadeIn"], result.source.index, shots)
            }

      }


    }



    shots.map((f,i) => console.log(`transition of shot ${i+1} is: `, f.filter))

    this.props.shotReorderCallback(shots)
    this.props.setShotCallback(shots)

  }

  setAllToExpanded(){
    this.state.shots.map( (f,i) => this.state.shots[i].expanded =  !this.state.AccordionsExpanded)
    this.setState({
      AccordionsExpanded: ! this.state.AccordionsExpanded
    })
  }

  setAccordionToExpanded(index){
    this.state.shots[index].expanded = !this.state.shots[index].expanded
    this.setState({})
  }


  colorCode(trans){
    const {t} = this.props

    if (trans === t('clips.videoeditormodal.transitionsdropdown.white')) return "#ffffff"
    if (trans === t('clips.videoeditormodal.transitionsdropdown.black')) return "#000000"
   // if (trans === null || undefined ) return "Standard"

    return null
  }

  transitionDropDownCallback(trans,idx,filter){ //trans-param is value of Dropdown ( Standard, Weiss, Schwarz)

    const {t} = this.props;

    let fo = this.state.shots[idx].filter.map( (f) =>
      {
      let parsedJSON = JSON.parse(f)

      if (parsedJSON.name === filter)  {

        if ( trans === t('clips.videoeditormodal.transitionsdropdown.standard')) {
          return null
        }

         parsedJSON.value = this.colorCode(trans)
      }


      return JSON.stringify(parsedJSON)

      }
    )

    this.state.shots[idx].filter = fo.filter(Boolean)


    if (this.state.shots[idx].filter.find(f => JSON.parse(f).name === filter) === undefined && trans!==t('clips.videoeditormodal.transitionsdropdown.standard')){
      this.state.shots[idx].filter.push(JSON.stringify({ name: filter, value: this.colorCode(trans)}))
    }

    console.log('fooooo is: ', fo )
    console.log('transitiontype is: ', trans )

    this.setState({})
    console.log("Transition is: ", this.state.shots[idx])
  }



getOverallShotLength(type){
  let stime = 0
  if (type === "all")
  this.state.shots.map(s => stime = stime + s.cutting.length )

  if (type === "cutted")
  this.state.shots.map(s => stime = stime + s.cutting.stop - s.cutting.start )

  return stime
}


resetAllTransitions(trans,filter){
  const {t} = this.props;

  this.state.shots.map( (shot,idx) =>{




  let fo = shot.filter.map( (f) =>
    {
    let parsedJSON = JSON.parse(f)

    if (filter.includes(parsedJSON.name))  {

      if ( trans === t('clips.videoeditormodal.transitionsdropdown.standard')) {
        return null
      }

       parsedJSON.value = this.colorCode(trans)
    }


    return JSON.stringify(parsedJSON)

    }
  )

  shot.filter = fo.filter(Boolean)
  console.log('transitions are: ', shot.filter)

  if (shot.filter.find(f => filter.includes(f.name)) === undefined && trans!==t('clips.videoeditormodal.transitionsdropdown.standard')){

    filter.map( mapfilter => {
      shot.filter.push(JSON.stringify({ name: mapfilter, value: this.colorCode(trans)}))
    })

  }

} )
this.setState({})

}


soundDefinitionCallback(c){
  this.setState({ sound: c})
  this.props.soundCallback(c)

}

  render() {

    const { classes,t } = this.props;
    const localthis = this;


    function getColorStringFromFilter(elem, filtertype) { // returns undefined if no filtertype is set, but filters available; null if filter is empty or filtercolor if filterIn/filterOut is set!



      if (elem.filter && elem.filter.length > 0){

        let fo =  elem.filter.map( f => {

          let jsonConverted = JSON.parse(f)
          if (jsonConverted.hasOwnProperty('name') )
          {

            if (jsonConverted.name === filtertype && jsonConverted.value === `#ffffff` ) return t('clips.videoeditormodal.transitionsdropdown.white')
            if (jsonConverted.name === filtertype && jsonConverted.value === `#000000` ) return t('clips.videoeditormodal.transitionsdropdown.black')
            return null
          }

        })

        return fo.filter(Boolean)[0] // eliminate null-Values; return could be undefinied!

      }
      else return null

    }

    function getColorCodeFromFilter(elem, filtertype) { // returns undefined if no filtertype is set, but filters available; null if filter is empty or filtercolor if filterIn/filterOut is set!



      if (elem.filter && elem.filter.length > 0){

        let fo =  elem.filter.map( f => {

          let jsonConverted = JSON.parse(f)
          if (jsonConverted.hasOwnProperty('name') )
          {

            if (jsonConverted.name === filtertype ) return jsonConverted.value
            return null
          }

        })

        return fo.filter(Boolean)[0] // eliminate null-Values; return could be undefinied!

      }
      else return null

    }

    return (
      <div>




<div style={{ marginBottom: "10px", display:"inline-flex", gap: "10px"}}>
  <p>
<Button color="primary" onClick={() => this.props.updateCallback()}><SaveButton />{' ' + t('storyboard.storyboardupdatepopup.applychanges')}</Button></p>

<p>
<SoundDefinitionModal sound={this.state.sound} sounds={this.props.sounds} callback={(c) => this.soundDefinitionCallback(c)} buttonLabel={t('storyboard.table_music')} />
</p>


          <UncontrolledTooltip placement="right" target={"videorenderbutton"}>
            {t('clips.shotdraganddropper.rendervideotooltip')}
          </UncontrolledTooltip>
          <p id={"videorenderbutton"}>
            <Button color="primary" onClick={() => this.props.renderCallback()}><VideoButton />{' ' + t('clips.shotdraganddropper.rendervideo')}</Button>
          </p>


<Cloudpicker cloudhash={this.props.cloudhash} 
cloudItemCallback={(selectedCloudItems) => this.props.cloudItemCallback(selectedCloudItems)} />

<UploadModal
                    externalhash={this.props.externalhash}
                    hash={this.props.hash}
                    mediasCallback={this.props.mediasCallback}
                  
                    modalname={"Material Upload"}
                   // sgid={this.props.supergroup.id}
                    media={this.props.media}
                    buttonLabel={"Upload"}
                    id={"id"}
                    />


</div>






        <Card>
          <CardHeader>{t('clips.shotdraganddropper.cardheadertext')}  { t('clips.shotdraganddropper.overalllength') + ' '}  <strong> {this.getOverallShotLength('all').toFixed(2) + ' / '} </strong>  {t('clips.shotdraganddropper.cuttedlength')}  <strong> {this.getOverallShotLength('cutted').toFixed(2) + ' / '} </strong>  {t('clips.shotdraganddropper.shotlength') + ' '} <strong>  {this.state.shots? this.state.shots.length : 0}</strong></CardHeader>
          <CardBody>

            <br />



            <div className="App" style={{ color: "#000000"}}>
      <FormControlLabel
        control={
          <Switch color="primary" checked={this.state.AccordionsExpanded} onChange={() => this.setAllToExpanded()} name={t('storyboard.storyboardcreator.open')} />
        }
        label={t('clips.shotdraganddropper.accordionsopen')}
      />
    </div>

            <DragDropContext onDragEnd={this.onDragEnd}

            >
                    <Droppable droppableId="droppable" >
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}

                        >
                          {this.state.shots && this.state.shots.length > 0 && this.state.shots.map((elem, index) => (
                            <Draggable
                            isDragDisabled = {this.state.isDragDisabled}
                            key={index} draggableId={ index.toString()} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >

{ index === 0 ?

<TransitionDropDownModal
type={"first"}
transitionid={ getColorStringFromFilter(elem,"fadeIn") || "Standard" }
backgroundColor = {getColorCodeFromFilter(elem,"fadeIn") || "rgba(255,255,255,0.1)"}
callback={(trans,elements) => {

  if (elements === "all") this.resetAllTransitions(trans, ["fadeIn", "fadeOut"])
  else{
  this.transitionDropDownCallback(trans, index, "fadeIn")
}
}
  }
/>

                                  : null
                                }


<Accordion  expanded={elem.expanded}  
onChange={() => !elem.deleted ? localthis.setAccordionToExpanded(index) : null} 
TransitionProps={{ unmountOnExit: true }}>
<AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls={`additional-actions${index + " " + elem.id}-control`}
                                      id={`additional-actions${index+ " " +elem.id}-id`}
                                    >


                                      <FormControlLabel
                                        aria-label="Acknowledge"
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        control={
                                          <div>
{/*                                           <Fab size="small" color="primary" aria-label="Add" className={classes.fab} onClick={() => localthis.setAccordionToExpanded(index)}>
                                            {elem.expanded ? <ExpandLess /> : <ExpandMore /> }
                                            </Fab>
*/}
                                            <Fab size="small" color="primary" aria-label="Add" className={classes.fab} onClick={() => localthis.newPosition(index, 'up')}>
                                              <UpIcon />
                                            </Fab>
                                            <Fab size="small" color="primary" aria-label="Add" className={classes.fab} onClick={() => localthis.newPosition(index, 'down')}>
                                              <DownIcon />
                                            </Fab>

                                          </div>
                                        }


                                        label=""
                                      />
                                  {
                                        !elem.expanded ?
                                            <CardHeader>
                                              {t('storyboard.storyboardcreator.shot')}-{`${index + 1}`}

                                              </CardHeader>
                                              : null
                                  }
                                  {
                                        !elem.expanded || elem.mediaType === "image" ?
                                             <img width={240}  src={elem.video.videoThumbLow} />

                                            : null
                                        }
                                            <Fab size="small" color="primary" aria-label="Add" className={classes.fablong} >

                                            <Timer />
                                            {elem.cutting.stop ? (elem.cutting.stop - elem.cutting.start).toFixed(2) : elem.cutting.start ? (elem.cutting.length - elem.cutting.start).toFixed(2) : elem.cutting.length.toFixed(2)}
                                            </Fab>

                                            <Fab size="small" style={{backgroundColor: "red", color: "#ffffff"}} aria-label="Add" className={classes.fab} onClick={() => {elem.deleted = true; localthis.remove(elem.id) } }>
                                              <DeleteIcon />
                                            </Fab>

{
             elem.video.hasOwnProperty('image') && (['jpg', 'png', 'jpeg']).includes(elem.video.image.split('.').pop().toLowerCase()) ?

             <Fab size="small" className={classes.fab} >

                <JImageEditor tableelement={true} save={true} link={elem.video.image}
                callback={ (data, decision) =>{

                   // if (decision === false) { elem.deleted = true; localthis.remove(elem.id) }
                    this.props.imageEditorCallback(data,elem.id, index, decision)


                }} />
                </Fab>
                : null
}


                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Card>
                                      <CardHeader>{t('storyboard.storyboardcreator.shot')}-{`${index + 1}`}{' '} <hr /> </CardHeader>

                                        <CardBody>
                                          <Row>


                                            <Col xl={3}
                                            key={"Videotrimmer-" + elem.id } onMouseOver={() => this.setState({isDragDisabled : true})} onMouseLeave={() => this.setState({isDragDisabled : false})}
                                            >





                                      <Videotrimmer
                                      valueCallback={(timeborders) => {this.props.valueCallback(elem.id, timeborders); } }
                                      step={0.01} min={0} max={elem.cutting.length}
                                      value={[elem.cutting.start, elem.cutting.stop]}
                                      id={index}
                                      video={elem.video.videoLow}
                                      poster={elem.video.hasOwnProperty('image') ? elem.video.image : elem.video.videoThumbLow}
                                      isImage={elem.video.hasOwnProperty('image') && (['jpg', 'png', 'jpeg']).includes(elem.video.image.split('.').pop().toLowerCase()) }
                                      />



                                            </Col>
                                            <Col xl={3}>

                                            <TransitionDropDown callback={(trans) => this.transitionDropDownCallback(trans, index, "fadeIn")}
                                            transitionid={ getColorStringFromFilter(elem,"fadeIn") || "Standard" }
                                            placeholder={t("clips.shotdraganddropper.fadeineffect") + ' ' + t('storyboard.storyboardcreator.shot') + '-' +`${index + 1}`}/>

                                            <TransitionDropDown callback={(trans) => this.transitionDropDownCallback(trans, index, "fadeOut")}
                                            transitionid={ getColorStringFromFilter(elem, "fadeOut") || "Standard" }
                                            placeholder={t("clips.shotdraganddropper.fadeouteffect") + ' ' + t('storyboard.storyboardcreator.shot') + '-' +`${index + 1}`}/>

                                            <Card>
                                              <CardHeader><Alert color="success">{t('clips.shotdraganddropper.statictext')}</Alert></CardHeader>
                                              <CardBody>
                                            <TitleUpdater title="title" subtitle="subTitle" titleCallback={(e) => this.props.titleCallback(elem.id, e)} row={elem.cuttingFeatures} />
                                            </CardBody>
                                            </Card>
                                            </Col>


                                            <Col
                                            key={"lowerThird-" + elem.id } onMouseOver={() => this.setState({isDragDisabled : true})} onMouseLeave={() => this.setState({isDragDisabled : false})}
                                            >




<Row>
                                              {
                                              elem.hasOwnProperty('modules') && elem.modules && elem.modules.length > 0 && elem.modules.find(f=>f.key==="lowerThirdBuilder") !== undefined ?
                                                  <div>

<div>
                                                    <UncontrolledTooltip placement="right" target={"changelowerthird" + elem.id}>
                                                      {t('clips.shotdraganddropper.changedynamictext')}
                                                    </UncontrolledTooltip>
                                                    <p id={"changelowerthird" + elem.id}>

                                                      <Fab size="small" color="primary" aria-label="Add" className={classes.fab} onClick={() => this.setState({ showlowerthird: !this.state.showlowerthird })}>
                                                        <LowerThirdIcon />
                                                      </Fab>
                                                    </p>
                                                    </div>
                                              {
                                                this.state.showlowerthird ?
                                              <div>

                                              <Card>
                                                <CardHeader><Alert color="success">{t('clips.shotdraganddropper.dynamictext')}</Alert></CardHeader>
                                              <CardBody>
                                            <LowerThird
                                            ltvalue = {JSON.parse(elem.modules.find(f => f.key === "lowerThirdBuilder").value)[0]}
                                            valueCallback={(timeborders) => {this.props.lowerthirdCallback(elem.id, timeborders); } }
                                            ltTitleCallback={(e) => this.props.ltTitleCallback(elem.id, e)}
                                            step={0.01}
                                            min={0}
                                            max={elem.cutting.length}
                                            value={[JSON.parse(elem.modules.find(f => f.key === "lowerThirdBuilder").value).startTime ? JSON.parse(elem.modules.find(f => f.key === "lowerThirdBuilder").value).startTime : 0
                                            , JSON.parse(elem.modules.find(f => f.key === "lowerThirdBuilder").value).stopTime ? JSON.parse(elem.modules.find(f => f.key === "lowerThirdBuilder").value).stopTime : elem.cutting.length]}
                                            video={elem.video.videoLow} poster={elem.video.videoThumbLow} />
                                            </CardBody>
                                            </Card>
                                            </div>
                                            : null
                                              }
                                            </div>
                                                  : <div>
                                                    <UncontrolledTooltip placement="right" target={"addlowerthird" + elem.id}>
                                                      {t('clips.shotdraganddropper.adddynamictext')}
                                                    </UncontrolledTooltip>
                                                    <p id={"addlowerthird" + elem.id}>
                                                      <Fab size="small" color="primary" aria-label="AddLowerThird" className={classes.root} onClick={() => { this.props.addLowerThirdCallback(elem.id); this.setState({ showlowerthird: !this.state.showlowerthird }) }}>
                                                        <Badge badgeContent={"+"} color="error" >
                                                          <LowerThirdIcon />
                                                        </Badge>
                                                      </Fab>
                                                    </p>
                                                  </div>
                                            }
</Row>


<Row>
                                            {
                                              elem.hasOwnProperty('audio') && elem.audio.hasOwnProperty('muteSound') && elem.audio.muteSound ?
                                              <Fab size="small" aria-label="LowerBackgroundmusic" className={classes.audiodown} onClick={() => { this.props.changeSpeech(elem.id);  }}>
                                              <SpeechbubbleIconDOWN />
                                              </Fab>

                                              :
                                              <Fab size="small" color="primary" className={classes.audioup} aria-label="IncreaseBackgroundmusic"  onClick={() => { this.props.changeSpeech(elem.id); }}>
                                              <SpeechbubbleIconUP />
                                              </Fab>
                                            }
</Row>
                                            </Col>

{
  /*
                                            <Col xs lg="1">

                                              <Fab size="small" color="primary" aria-label="Delete" className={classes.fab} onClick={() => localthis.delStoryboardShot(index)} >
                                                <DeleteIcon />
                                              </Fab>

                                              <Fab size="small" color="primary" aria-label="Save" className={classes.fab} onClick={(e) => this.updateStoryboardToJoobsterServer(e)} >
                                                <SaveButton />
                                              </Fab>


                                            </Col>

  */
}

                                          </Row>



                                        </CardBody>
                                        <CardFooter>
                                        </CardFooter>


                                      </Card>
                                    </AccordionDetails>
                                  </Accordion>

                                  {
                                    index !== this.state.shots.length - 1 ?  // element in the middle of list

                                    <TransitionDropDownModal
                                    type={"middle"}
                                    transitionid={ getColorStringFromFilter(elem,"fadeOut") || "Standard" }
                                    backgroundColor = {getColorCodeFromFilter(elem,"fadeOut") || "rgba(255,255,255,0.1)"}
                                    callback={(trans, elements) => {

                                      if (elements === "all") this.resetAllTransitions(trans, ["fadeIn", "fadeOut"])
                                      else{
                                      this.transitionDropDownCallback(trans, index, "fadeOut");
                                      this.transitionDropDownCallback(trans, index+1, "fadeIn")
                                      }
                                    }}
                                    />

                                  :

                                  <TransitionDropDownModal
                                  type={"last"}
                                  transitionid={ getColorStringFromFilter(elem,"fadeOut") || "Standard" }
                                  backgroundColor = {getColorCodeFromFilter(elem,"fadeOut") || "rgba(255,255,255,0.1)"}
                                  callback={(trans, elements) => {
                                    if (elements === "all") this.resetAllTransitions(trans, ["fadeIn", "fadeOut"])
                                    else{
                                    this.transitionDropDownCallback(trans, index, "fadeOut")
                                    }
                                  }
                                  }
                                  />
                                }
                                </div>

                              )}

                            </Draggable>

                          ))}
                          {provided.placeholder}

                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>



          </CardBody>
          <CardFooter style={{ background: 'lightblue' }}>

          </CardFooter>
        </Card>



        <Button color="primary" onClick={() => this.props.updateCallback()}><SaveButton />{' ' + t('storyboard.storyboardupdatepopup.applychanges')} </Button>


      </div>
    );
  }
}


export default withTranslation()(withStyles(styles)(ShotDragAndDropper));


