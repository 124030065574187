import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import './backgroundonhover.css'
import { withStyles } from '@mui/styles';
import 'react-bootstrap-table-next/dist/react-bootstrap-custom-table2.min.css';
import "../../../node_modules/video-react/dist/video-react.css";
import { Player, BigPlayButton } from 'video-react';
import Rowcheckbox   from './Rowcheckbox';
import { withTranslation } from 'react-i18next'
import '../../i18n'


const styles = theme => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
    rootIndicator: {
		flexGrow: 1,
	  },
	chip: {
		margin: theme.spacing,
	},
	extendedIcon: {
		marginRight: theme.spacing,
	},
    fab: {
		margin: theme.spacing,
		display: 'flex',
		//marginRight: "auto" ,
		//justifyContent: 'flex-end',
	   // position: 'absolute',
	   // right: '0',
		float: 'left',
		background: 'yellow',
		height: "46px",
		fontFamily: "Roboto, sans-serif",
		borderRadius: "0px",


	  },

});


class CloudVideos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shots: this.props.shots || null,
			selectedRows: [], // holds current selected rows, checked = true

    };
		this.setSelectedRow = this.setSelectedRow.bind(this);


  }



	setSelectedRow(rowid, dataentry, checkedval) {

		var rowarray = this.state.selectedRows;
		var index = this.getIndexOfObjectByKey(rowarray, 'id', rowid);

		if (index == null) {
			rowarray.push({ id: rowid, data: dataentry, checked: checkedval });
		} else {
			rowarray.splice(index, 1);
		}

		this.setState({ selectedRows: rowarray });
		console.log('Anzahl an selektierten Reihen: ', this.state.selectedRows)
    this.props.checkedRows(this.state.selectedRows)
		return null;
	}

  static getDerivedStateFromProps(props, state){

    if (JSON.stringify(props.shots) !== JSON.stringify(state.shots) ){

      console.log('shots are: ', props.shots)

      return { shots: props.shots}
    }

    return null
  }

	getIndexOfObjectByKey(array, key, value) {
		for (var i = 0; i < array.length; i++) {
			if (array[i][key] === value) {
				return i;
			}
		}
		return null;
	}


  render(){


    var localthis = this;
    const {t} = this.props





		function selectFormatter(cell, row, rowIndex, formatExtraData) {
			return (
				<div>
					<Rowcheckbox data={row} type="checkbox"
          variableTransfer={localthis.setSelectedRow}
          isIn={localthis.getIndexOfObjectByKey(localthis.state.selectedRows, 'id', row.id) != null}
          clipdata={localthis.state.selectedRows}
          //sgid={localthis.props.supergroup.id} 
		  />
				</div>
			);
		}


		function videoFormatter(cell, row, rowIndex, formatExtraData) {

			return (
				<Player fluid={false} playsInline preload={"none"} poster={row.video.videoThumbLow} src={row.video.video}

        width="480" >
				<BigPlayButton position="center" />
					</Player>
			)
		}

		function shotIDFormatter(cell, row, rowIndex, formatExtraData) {
			const {t} = localthis.props;
			return (

        <p>{row.id}</p>

			)
		}


		const columns = [
			{
				dataField: 'id',				
				formatter: selectFormatter,
				formatExtraData: [this.state.selectedRows, this.setSelectedRow],
				headerStyle: (colum, colIndex) => {
					return { width: '10%' };
				},				
			},
			{
				dataField: 'video.video',
				text: t('clips.cloudvideos.tabletext.video'),
				formatter: videoFormatter,
				headerStyle: (colum, colIndex) => {
					return { width: '95%' };
				},
			},


		];



		const selectRow = {
			mode: 'checkbox',
			classes: 'selection-row',
			onSelect: (row, isSelect, rowIndex, e) => {
			},
			onSelectAll: (isSelect, rows, e) => {
				console.log(isSelect);
				console.log(rows);
				console.log(e);
			}

		};
    /*

		function selectFormatter(column, colIndex, { sortElement, filterElement }) {
			return (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					{ filterElement}
					{ column.text}
					{ sortElement}
				</div>
			);
		}
*/

		const rowEvents = {
			onClick: (e, row, rowIndex) => {
				console.log(e + ' ' + row + ' ' + rowIndex);
			}
		}

		const customTotal = (from, to, size) => (
			<span className="react-bootstrap-table-pagination-total">
				&nbsp;	{t('clips.tablePaginationName')} { from} - { to} {t('clips.tablePaginationNameFrom')} { size}
			</span>
		);

		const options = {
			paginationSize: 4,
			pageStartIndex: 0,
			// alwaysShowAllBtns: true, // Always show next and previous button
			// withFirstAndLast: false, // Hide the going to First and Last page button
			// hideSizePerPage: true, // Hide the sizePerPage dropdown always
			// hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
			firstPageText: t('general.tablefirstpagetext'),
			prePageText: t('general.tableprepagetext'),
			nextPageText: t('general.tablenextpagetext'),
			lastPageText: t('general.tablelastpagetext'),
			nextPageTitle: t('general.tablenextpagetitle'),
			prePageTitle: t('"general.tableprepagetitle'),
			firstPageTitle: t('general.tablefirstpagetitle'),
			lastPageTitle: t('general.tablelastpagetitle'),
			showTotal: true,
			paginationTotalRenderer: customTotal,
			sizePerPageList: [{
				text: '10', value: 10
			}, {
				text: '25', value: 25
			}, {
				text: '50', value: 50
			}, {
				text: 'All', value: localthis.state.shots ? localthis.state.shots.length : 0
			}] // A numeric array is also available. the purpose of above example is custom the text
		};



    return(

<div>
      {
        this.state.shots ?


      <BootstrapTable keyField='id'
      //ref='table'
      bootstrap4
      data={this.state.shots}
      wrapperClasses="table-responsive"
      filter={filterFactory()}
      filterPosition="top"

      columns={columns}
      //	selectRow={selectRow}
      rowEvents={rowEvents}
      pagination={paginationFactory(options)}
    />

    : null
      }
</div>

    )
  }



}



export default withTranslation()(withStyles(styles)(CloudVideos));
