//import './App.css';
import './components/Initialpage'
import Initialpage from './components/Initialpage';

function App() {
  return (
    
      <Initialpage />

    
  );
}

export default App;
