import React from 'react';
import { withSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next'
import '../../i18n'


class TitleUpdater extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      [this.props.title]: this.props.row[this.props.title] || null,
      [this.props.subtitle]: this.props.row[this.props.subtitle] || null,
    }
  //  this._handleKeyPress = this._handleKeyPress.bind(this);
  }


handleInputChange(e) {
  this.setState({
      [e.target.name]: e.target.value
  });

  let localJSON = this.props.row
  localJSON[e.target.name] = e.target.value
  this.props.titleCallback(localJSON)

  console.log('state is: ', e.target.value)

}


  render() {

    const {t} = this.props;

    return (
      <div>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">{t('clips.videotitle_title')}</span>
          </div>
          <input type="text" name={this.props.title} className="form-control" onChange={(e) => this.handleInputChange(e)}   value={this.state[this.props.title]} />
        </div>

        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon2">{t('clips.videotitle_subtitle')}</span>
          </div>

          <input type="text" name={this.props.subtitle} className="form-control" onChange={(e) => this.handleInputChange(e)}   value={this.state[this.props.subtitle]}  />
        </div>
      </div>
    );
  }
}


  export default  withTranslation()(withSnackbar(TitleUpdater));


